import numeral from "numeral";

export const TokenName = "mohaToken";

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const formatKoreanPhoneNumber = (phoneNumber) => {
  // 숫자만 남기기
  const cleanNumber = phoneNumber.replace(/[^0-9]/g, "");

  // 전화번호가 11자리인 경우 (앞에 0을 제거한 후 10자리로 변환)
  if (cleanNumber.length === 11 && cleanNumber.startsWith("010")) {
    return cleanNumber.replace(/^010(\d{4})(\d{4})$/, "10-$1-$2");
  }

  // 전화번호가 10자리인 경우 (지역번호 2자리)
  if (cleanNumber.length === 10) {
    return cleanNumber.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
  }

  // 그 외: 변환하지 않음
  return phoneNumber;
};

export const validatePhoneNumber = (phone) => {
  // 1. 숫자만 남기기
  const cleanNumber = phone.replace(/[^0-9]/g, "");

  // 2. 길이와 시작 번호 검증
  const isValid = cleanNumber.length === 10 || cleanNumber.length === 11; // 길이 검증
  const startsWithValidPrefix =
    cleanNumber.startsWith("010") ||
    cleanNumber.startsWith("011") ||
    cleanNumber.startsWith("016");

  // 3. 최종 결과 반환
  return isValid && startsWithValidPrefix;
};


export const formattedNumber = (number, format = "0,0") => {
  return numeral(number).format(format);
};

export const formatDate = (isoString) => {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const validatePassword = (password) => {
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[\d!@#$%^&*()_+{}|:"<>?]).{8,}$/;

  return passwordRegex.test(password);
};


export const ProductLimit = 300


export function convertToDomesticNumber(internationalNumber) {
  // '+82'을 '0'으로 변경하고, 불필요한 공백과 하이픈을 제거합니다.
  return internationalNumber.replace('+82', '0').replace(/[-\s]/g, '');
}

export function convertToInternationalNumber(domesticNumber) {
  // '0'을 '+82'로 변경하고, 하이픈을 추가하여 포맷을 맞춥니다.
  return '+82 ' + domesticNumber.substring(1, 3) + '-' + domesticNumber.substring(3, 7) + '-' + domesticNumber.substring(7);
}
