import { Fragment, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import test from "../../assets/img/logo/logo.png";
import { FaFileUpload } from "react-icons/fa";

import {
  ImageContainer,
  OrderDayText,
  OrderDetailBox,
  OrderDetailOptionBox,
  OrderDetailTextBox,
  OrderFlexCenterBox,
  OrderFlexSB,
  OrderGroupNameBox,
  OrderGroupValueBox,
  OrderMemoBox,
  OrderNoMemoBox,
  OrderPayBox,
  OrderPayBox2,
  StyledImage,
} from "../../assets/style/Orderlist";
import { formatDate, formattedNumber } from "../../util/const";
import { API_URL } from "../../api/axiosConfig";
import { ModalFooter } from "../../assets/style/Modal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  FlexBox,
  FlexCenterBox,
  FlexDiv,
  FlexStartBox,
  SizeBoxW5,
  SpanFont,
  SpanTextModalBoldOne,
  SpanTextModalBoldTwo,
} from "../../assets/style/Common";
import {
  StyledText,
  StyledTextH4,
  StyledTextH5,
} from "../../assets/style/GlobalStyle";

const OrderDetailPage = () => {
  const location = useLocation();
  const { order } = location.state || {};
  let { pathname } = useLocation();
  const { t } = useTranslation();
  const userEmail = useSelector((state) => state.userData?.email);
  const currency = useSelector((state) => state.currency);

  console.log("order디테일은 뭐가오고있는대", order);
  const getOrderStatusLabel = (status) => {
    switch (status) {
      case "pending":
        return "최초 주문 생성 상태";
      case "paid":
        return `${t("payment_completed")}`;
      case "processing":
        return `${t("in_production")}`;
      case "completed":
        return `${t("production_completed")}`;
      case "cancelled":
        return `${t("order_canceled")}`;
      default:
        return `${t("order_pending")}`;
    }
  };

  const paymentMethodMap = {
    naverpay: "네이버페이",
    kakaopay: "카카오페이",
    samsungpay: "삼성페이",
    creditcard: "신용카드",
  };

  return (
    <Fragment>
      <SEO
        titleTemplate="OrderDetailPage"
        description="OrderDetailPage page of flone react minimalist eCommerce template."
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: `${t("home")}`, path: process.env.PUBLIC_URL + "/" },
            {
              label: `${t("order_detail_text")}`,
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />

        <div className="myaccount-area pb-80 pt-100">
          <div className="container">
            <div className="row">
              <div className="ms-auto me-auto col-lg-9">
                <div className="myaccount-wrapper">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item
                      eventKey="0"
                      className="single-my-account mb-20"
                    >
                      <Accordion.Header className="panel-heading">
                        <StyledText>{t("order_items")}</StyledText>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="myaccount-info-wrapper">
                          <div className="account-info-wrapper">
                            <StyledTextH4>
                              <OrderDayText>
                                {formatDate(order.created_at)} {t("payment")}
                              </OrderDayText>
                            </StyledTextH4>
                            <StyledTextH5>
                              {getOrderStatusLabel(order.status)}
                            </StyledTextH5>
                          </div>
                          {order.items.map((detail, index) => (
                            <div className="row" key={index}>
                              <div className="col-lg-3 col-md-3">
                                <div className="billing-info">
                                  {/* <label>First Name</label> */}
                                  <ImageContainer
                                    style={{
                                      flexShrink: 0,
                                    }}
                                  >
                                    <StyledImage
                                      className="img-fluid"
                                      src={`${API_URL}${detail.product_image}`}
                                      alt=""
                                    />
                                  </ImageContainer>
                                  {/* <input type="text" /> */}
                                  {detail.selected_options.filter(
                                    (option) => option.option_type === "file"
                                  ).length > 0 && (
                                    <div style={{ display: `flex` }}>
                                      <div
                                        style={{
                                          marginRight: `5px`,
                                        }}
                                      >
                                        <FaFileUpload color="#f68063" />{" "}
                                        {t("upload_file")}
                                      </div>
                                      {
                                        detail.selected_options.filter(
                                          (option) =>
                                            option.option_type === "file"
                                        ).length
                                      }
                                      {t("items_count")}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-9 col-md-9">
                                <div className="billing-info">
                                  <Link
                                    to={
                                      process.env.PUBLIC_URL +
                                      `/product/${detail?.product_id}`
                                    }
                                  >
                                    <StyledTextH4>
                                      {detail.product_name}
                                    </StyledTextH4>
                                  </Link>
                                  <FlexStartBox>
                                    <SpanFont>
                                      {currency.currencySymbol}
                                    </SpanFont>
                                    <SizeBoxW5 />
                                    {formattedNumber(detail.price, `0,0`)}{" "}
                                    {formattedNumber(detail.quantity)}
                                    {t("items_count")}
                                  </FlexStartBox>

                                  {detail.selected_options
                                    .filter(
                                      (option) =>
                                        !["file", "text", "textarea"].includes(
                                          option.option_type
                                        )
                                    )
                                    .map((option, index) => (
                                      <OrderFlexCenterBox key={index}>
                                        <OrderDetailOptionBox>
                                          {t("option")} {index + 1}
                                        </OrderDetailOptionBox>

                                        <div
                                          style={{
                                            marginRight: `5px`,
                                          }}
                                        >
                                          {option.option_group_name} :
                                        </div>
                                        <div>
                                          {
                                            option.selected_values[0]
                                              .display_name
                                          }
                                        </div>
                                      </OrderFlexCenterBox>
                                    ))}
                                </div>
                                {detail.selected_options.filter((option) =>
                                  ["text", "textarea"].includes(
                                    option.option_type
                                  )
                                ).length === 0 ? (
                                  <></>
                                ) : (
                                  detail.selected_options
                                    .filter((option) =>
                                      ["text", "textarea"].includes(
                                        option.option_type
                                      )
                                    )
                                    .map((option, index) => (
                                      <div key={index}>
                                        <OrderGroupNameBox>
                                          {" "}
                                          - {option.option_group_name}
                                        </OrderGroupNameBox>
                                        <OrderGroupValueBox>
                                          {option.selected_values[0].value}
                                        </OrderGroupValueBox>
                                      </div>
                                    ))
                                )}
                              </div>
                            </div>
                          ))}

                          <OrderPayBox />
                          <div className="col-lg-12 col-md-12">
                            <div className="billing-info">
                              <FlexCenterBox>
                                <OrderDetailBox>
                                  <SpanTextModalBoldOne fontSize="20px">
                                    M
                                  </SpanTextModalBoldOne>
                                  <SpanTextModalBoldTwo fontSize="20px">
                                    O
                                  </SpanTextModalBoldTwo>
                                  <SpanTextModalBoldOne fontSize="20px">
                                    H
                                  </SpanTextModalBoldOne>
                                  <SpanTextModalBoldTwo fontSize="20px">
                                    A
                                  </SpanTextModalBoldTwo>
                                  <OrderDetailTextBox>
                                    {t("moha_using")}
                                  </OrderDetailTextBox>
                                </OrderDetailBox>
                              </FlexCenterBox>
                              <ModalFooter>
                                <span>{t("payment")}</span>
                                <FlexDiv
                                  style={{
                                    display: `flex`,
                                  }}
                                >
                                  {paymentMethodMap[order.payment_method]}{" "}
                                  <SizeBoxW5 />
                                  <SpanFont>{currency.currencySymbol}</SpanFont>
                                  <SizeBoxW5 />
                                  {formattedNumber(order.total_amount, `0,0`)}
                                </FlexDiv>
                              </ModalFooter>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item
                      eventKey="1"
                      className="single-my-account mb-20"
                    >
                      <Accordion.Header className="panel-heading">
                        <StyledText>{t("payment_data")}</StyledText>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="myaccount-info-wrapper">
                          <div className="account-info-wrapper">
                            <OrderFlexSB>
                              <div>{order.shipping_name}</div>
                              <OrderDayText>
                                {formatDate(order.created_at)}
                              </OrderDayText>
                            </OrderFlexSB>

                            <div>{order.shipping_phone}</div>
                            <div>{userEmail}</div>
                            <div>{order.shipping_address}</div>
                            <div>
                              {t("payment_type")} :{" "}
                              {paymentMethodMap[order.payment_method]}{" "}
                            </div>
                            <OrderPayBox2 />
                            <StyledTextH5>{t("request_notes")}</StyledTextH5>
                            {!order.shipping_memo ? (
                              <OrderNoMemoBox></OrderNoMemoBox>
                            ) : (
                              <div>{order.shipping_memo}</div>
                            )}
                            <OrderMemoBox>
                              <div>{t("order_total")}</div>
                              <FlexBox>
                                {t("total")}
                                <SizeBoxW5 />
                                <SpanFont>{currency.currencySymbol}</SpanFont>
                                <SizeBoxW5 />
                                {formattedNumber(
                                  order.total_amount,
                                  `0,0`
                                )}{" "}
                              </FlexBox>
                            </OrderMemoBox>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default OrderDetailPage;
