import api from "../axiosConfig";

export const GetProduct= async (params)=>{
    try {
        // const data = {
        //   email: email,
        // };
    
        const resp = await api.get("/api/v1/products",{
            params:params,
            // headers:{
            //   errorpage:true
            // }
        });
    
        return resp.data
       
      } catch (error) {
        console.error("Error fetching products:", error);
        return [];
      }
}



export const GetProductDetail= async (id)=>{
    try {
        // const data = {
        //   email: email,
        // };
    
        const resp = await api.get("/api/v1/products/"+id);
    
        return resp.data
       
      } catch (error) {
        console.error("Error fetching products:", error);
        return [];
      }
}