import { bool } from "prop-types";

export const SET_USER_DATA = "SET_USER_DATA";
export const UPDATE_USER_CONTACT_INFO = "UPDATE_USER_CONTACT_INFO";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const PROFILE_CHANGE = "PROFILE_CHANGE";
export const INCREMENT_COUNTER = "INCREMENT_COUNTER";
export const ADDRESS_CHANGE = "ADDRESS_CHANGE";

export const setUserData = (userData) => {
  return {
    type: SET_USER_DATA,
    payload: userData,
  };
};

export const updateUserData = (contactInfo) => ({
  type: UPDATE_USER_CONTACT_INFO,
  payload: contactInfo,
});

export const updateUserProfile = (profile) => ({
  type: UPDATE_USER_PROFILE,
  payload: profile,
});

export const reRandData = () => ({
  type: INCREMENT_COUNTER,
});

export const upDateUserAddress = (address) => ({
  type: ADDRESS_CHANGE,
  payload: address,
});
