import axios from "axios";
import { getCookie } from "../util/Cookie";
import { store } from "../store/store";
import { authError } from "../store/action/AuthGuardAction";
import { TokenName } from "../util/const";

// const baseURL = 'http://localhost:3003/api/v1';
// export const API_URL = "http://168.131.30.60:8011";
export const API_URL = "http://xraidev.store:3003";
const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const productAPI = {
  getProducts: async (params) => {
    try {
      const response = await api.get("/products", { params });
      return response.data;
    } catch (error) {
      console.error("Error fetching products:", error);
      return [];
    }
  },

  getProduct: async (id) => {
    try {
      const response = await api.get(`/products/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching product:", error);
      throw error;
    }
  },
};
api.interceptors.request.use(
  (config) => {
    // config.headers["API-Key"] = "COFFEE1234!!!";
    // config.headers["Access-Control-Allow-Origin"] = "*";
    if (config.headers.requiresAuth !== false) {
      const token = getCookie(TokenName);
      // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo1MSwiZXhwIjoxNzMzOTc2NDE4fQ.hjQZVkxfBhiPZ2-OD8Xtg5UXAEAHWzjKcvLAhTE2xwI"
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    delete config.headers.requiresAuth;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 응답 인터셉터 추가
api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API Error:", error);
    if (error.response) {
      // 서버가 응답을 반환한 경우
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      
      
      if ((error.response.status === 401 || error.response.status === 403 ) &&
      error.config.headers.isAuth!=="true"){
      // error.response.data.detail!=="이메일 또는 비밀번호가 일치하지 않습니다") {
        
        store.dispatch(authError(error.response.status));
      }
    } else if (error.request) {
      // 요청이 전송되었으나 응답을 받지 못한 경우
      console.error("No response received");
    }
    if (error.config.headers["errorpage"]) {
      window.location.href = "/error";
    }

    return Promise.reject(error);
  }
);

// 요청 이터셉터 추가
api.interceptors.request.use(
  (config) => {
    // 쿠키에서 토큰을 가져옵니다.
    const token = getCookie(TokenName);

    // 토큰이 있으면 Authorization 헤더에 추가
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config; // 수정된 config를 반환하여 요청을 진행
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      store.dispatch(authError(error.response.status));
    }
    return Promise.reject(error); // 요청 에러 처리
  }
);
export default api;
