import api from "../axiosConfig";

export const Orders = async ({ page, limit }) => {
  try {
    const resp = await api.get("/api/v1/orders", {
      params: {
        page: page,
        limit: limit,
      },
    });
    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("주문목록조회 실패", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
