import mohalogo from "../../assets/img/logo/footer_logo.png";
import {
  FooterDivBox,
  FooterDivFlexBox,
  FooterImg,
  FooterMdBox,
  FooterPointerBox,
  FooterTextAlign,
  FooterWMSvg,
  FooterBox,
  FooterFlexBox,
  FooterFlexFlex,
  FooterXxlBox,
} from "./footerStyle";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState("");

  useEffect(() => {
    // 초기 언어 확인
    const lng = i18next.language || i18next.resolvedLanguage || "en";
    setCurrentLanguage(lng);
    // console.log("변경된언어?", currentLanguage);
    // 언어 변경 이벤트 핸들러
    const handleLanguageChange = (lng) => {
      setCurrentLanguage(lng);
    };
    // i18next 이벤트 등록
    i18next.on("languageChanged", handleLanguageChange);

    // 언마운트 시 이벤트 해제
    return () => {
      i18next.off("languageChanged", handleLanguageChange);
    };
  }, [currentLanguage]);

  const handleInfoRoute = (i18) => {
    if (i18 === "en") {
      navigate("/infoEn");
    } else {
      navigate("/infoKo");
    }
  };

  const handleConditionRoute = (i18) => {
    if (i18 === "en") {
      navigate("/ConditionEn");
    } else {
      navigate("/ConditionKo");
    }
  };

  return (
    <div>
      <FooterBox className="bg-gray-3">
        <FooterXxlBox className="container-xxl">
          <FooterFlexBox>
            <div>
              <FooterDivBox>
                <FooterImg src={mohalogo} alt="dd" />
              </FooterDivBox>
              <br></br>
              <FooterTextAlign>
                {t("footer_1")}
              </FooterTextAlign>
              <div>
              {t("footer_2")}
              </div>
              <div>
                TEL 062-530-3470 | FAX 062-530-3449 |MALE xrai.inc@gmail.com
              </div>
            </div>
          </FooterFlexBox>
          <FooterFlexFlex>
            <div>
              <FooterMdBox></FooterMdBox>
              <FooterDivFlexBox>
                <FooterPointerBox
                  onClick={() =>
                    (window.location.href = "https://xrai-api.com/page_btLQ36")
                  }
                >
                  {t("company_overview")}
                </FooterPointerBox>
                <FooterPointerBox
                  onClick={() => handleConditionRoute(currentLanguage)}
                  // onClick={() => navigate("/treatyPage")}
                >
                  {t("terms_service")}
                </FooterPointerBox>
                <FooterPointerBox
                  onClick={() => handleInfoRoute(currentLanguage)}
                  // onClick={() => navigate("/informationPage")}
                >
                  {t("privacy_policy")}
                </FooterPointerBox>
              </FooterDivFlexBox>
              <div>통신판매번호:2021-광주북구-1398</div>
              <div>
                <FooterWMSvg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="copyright"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 448c-110.532 0-200-89.451-200-200 0-110.531 89.451-200 200-200 110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200zm107.351-101.064c-9.614 9.712-45.53 41.396-104.065 41.396-82.43 0-140.484-61.425-140.484-141.567 0-79.152 60.275-139.401 139.762-139.401 55.531 0 88.738 26.62 97.593 34.779a11.965 11.965 0 0 1 1.936 15.322l-18.155 28.113c-3.841 5.95-11.966 7.282-17.499 2.921-8.595-6.776-31.814-22.538-61.708-22.538-48.303 0-77.916 35.33-77.916 80.082 0 41.589 26.888 83.692 78.277 83.692 32.657 0 56.843-19.039 65.726-27.225 5.27-4.857 13.596-4.039 17.82 1.738l19.865 27.17a11.947 11.947 0 0 1-1.152 15.518z"
                  ></path>
                </FooterWMSvg>
                2023.XRAI co.,Ltd. ALL RIGHTS RESERVED.
              </div>
            </div>
          </FooterFlexFlex>
          {/* <FooterFlexFlex></FooterFlexFlex> */}
        </FooterXxlBox>
      </FooterBox>
    </div>
  );
};
