import api from "../axiosConfig";

export const AuthLogin = async (email, pw) => {
  try {
    const data = {
      email: email,
      password: pw,
    };

    const resp = await api.post("/api/v1/auth/login", data,{headers:{
      isAuth:true
    }});

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("로그인 에러", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};

// 이메일 인증
export const AuthVerifyEmail = async (tk) => {
  try {
    const resp = await api.post("/api/v1/auth/verify-email", {
      token: tk,
    });

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("이메일 인증 실페", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};

// 유저정보 저장
export const AuthMe = async (token) => {
  try {
    const resp = await api.get("/api/v1/auth/me", {
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    });
    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("유저정보 호출 에러", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
