import { AuthForgotPassword } from "../../api/Authentication/AuthForgotPassword";
import { AuthResetPassword } from "../../api/Authentication/AuthResetPassword";
import { validatePassword } from "../../util/const";

export const ForgotEmailHandler = (e, setEmail) => {
  setEmail(e.target.value);
};

export const handleForgotEmail = async (
  email,
  setEmailValueError,
  setEmailValueCheck,
  setPostEmail,
  setLoading
) => {
  try {
    setLoading(true);
    const resp = await AuthForgotPassword(email);

    if (resp.msg === "Success") {
      setEmailValueCheck(true);
      setEmailValueError(false);
      setPostEmail(true);
      setLoading(false);
    }

    if (resp.msg === "Fail") {
      setEmailValueError(true);
      setPostEmail(false);
      setLoading(false);
    }
  } catch (error) {
    console.log("비밀번호 변경 이메일 전송 실패", error);
    setEmailValueError(true);
    setPostEmail(false);
    setLoading(false);
  }
};

export const ForgotChangePasswordHandler = (e, setPassword) => {
  setPassword(e.target.value);
};

export const ForgotChangeCheckPasswordHandler = (e, setCheckPassword) => {
  setCheckPassword(e.target.value);
};

export const handleForgotPw = async (
  isPassword,
  isCheckPassword,
  isTokenOk,
  setPwSameCheck,
  setPwTrim,
  setTimeOver,
  setButtonLoading,
  setIsModalOpen,
  setPwRegex
) => {
  try {
    setButtonLoading(true);
    setTimeOver(false);
    let pass = true;
    if (isPassword.trim() === "") {
      setPwTrim(true);
      setButtonLoading(false);
      pass = false;
    } else if (isPassword !== isCheckPassword) {
      setPwTrim(false);
      setButtonLoading(false);
      setPwSameCheck(true);
      pass = false;
    } else {
      if (isPassword.length <= 8) {
        setPwTrim(true);
        setPwSameCheck(false);
        setButtonLoading(false);
        pass = false;
      }
    }

    if (isCheckPassword.trim() === "") {
      setPwTrim(true);
      setButtonLoading(false);
      pass = false;
    } else if (isPassword !== isCheckPassword) {
      setPwTrim(false);
      setButtonLoading(false);
      setPwSameCheck(true);
      pass = false;
    } else {
      if (isCheckPassword.length <= 8) {
        setPwTrim(true);
        setPwSameCheck(false);
        setButtonLoading(false);
        pass = false;
      }
    }

    /// 비밀번호 규칙확인
    if (!validatePassword(isPassword)) {
      /// 규칙에 맞지 않는경우

      pass = false;
      setPwRegex(true);
      setPwTrim(true);
      setButtonLoading(false);
    } else {
      setPwRegex(false);
      setPwTrim(false);
      setButtonLoading(false);
    }

    if (pass === false) {
      return;
    } else {
      setPwTrim(false);
      setPwSameCheck(false);
    }

    const resp = await AuthResetPassword(isTokenOk, isPassword);

    if (resp.msg === "Success") {
      setButtonLoading(false);
      setIsModalOpen(true);
    }

    if (resp.msg === "Fail") {
      console.log("비밀번호 변경 실패", resp);
      setTimeOver(true);
      setButtonLoading(false);
    }
  } catch (error) {
    console.log("비밀번호 변경 오류".error);
    setTimeOver(true);
    setButtonLoading(false);
    return;
  }
};
