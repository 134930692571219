import styled from "styled-components";

export const LoginInputBox = styled.input.withConfig({
  shouldForwardProp: (prop) =>
    ![
      "hasError",
      "marginBottom",
      "fontFamily",
      "borderTopLeftRadius",
      "borderTopRightRadius",
      "borderBottomLeftRadius",
      "borderBottomRightRadius",
    ].includes(prop),
})`
  font-family: ${(props) =>
    props.fontFamily || "Arial, sans-serif"}; /* 기본 폰트 설정 */

  background-color: transparent;
  border: 1px solid ${(props) => (props.hasError ? "red" : "#ebebeb")};
  color: #333;
  font-size: 14px;
  height: 45px;

  /* 사용시 다음과 같이 사용 marginBottom="20px" */
  margin-bottom: ${(props) => props.marginBottom || "30px"};
  padding: 0 15px;
  /* margin-right: 10px; */
  box-shadow: ${(props) =>
    props.hasError ? "0 0 8px rgba(255, 0, 0, 0.5)" : "none"};
  transition: border 0.3s ease, box-shadow 0.3s ease;

  /* 테두리 둥글기 설정 */
  border-top-left-radius: ${(props) => props.borderTopLeftRadius || "0px"};
  border-top-right-radius: ${(props) => props.borderTopRightRadius || "0px"};
  border-bottom-left-radius: ${(props) =>
    props.borderBottomLeftRadius || "0px"};
  border-bottom-right-radius: ${(props) =>
    props.borderBottomRightRadius || "0px"};
  &:focus {
    border-color: ${(props) =>
      props.hasError ? "red" : "#FFAA77"}; /* 기본은 파란색 */

    outline: none; /* 기본 outline 제거 */
  }
`;

export const InputBox = styled.input.withConfig({
  shouldForwardProp: (prop) =>
    prop !== "hasError" && prop !== "marginBottom" && prop !== "fontFamily",
})`
  font-family: ${(props) =>
    props.fontFamily || "Arial, sans-serif"}; /* 기본 폰트 설정 */

  background-color: transparent;
  border: 1px solid ${(props) => (props.hasError ? "red" : "#ebebeb")};
  color: #333;
  font-size: 14px;
  height: 45px;
  /* 사용시 다음과 같이 사용 marginBottom="20px" */
  margin-bottom: ${(props) => props.marginBottom || "30px"};
  padding: 0 15px;
  margin-right: 10px;
  box-shadow: ${(props) =>
    props.hasError ? "0 0 8px rgba(255, 0, 0, 0.5)" : "none"};
  transition: border 0.3s ease, box-shadow 0.3s ease;
`;

export const SmallInputBox = styled.input.withConfig({
  shouldForwardProp: (prop) =>
    prop !== "hasError" && prop !== "marginBottom" && prop !== "fontFamily",
})`
  font-family: ${(props) =>
    props.fontFamily || "Arial, sans-serif"}; /* 기본 폰트 설정 */
  background-color: transparent;
  border: 1px solid ${(props) => (props.hasError ? "red" : "#ebebeb")};
  color: black !important;
  font-size: 14px;
  height: 30px;
  /* 사용시 다음과 같이 사용 marginBottom="20px" */
  margin-bottom: ${(props) => props.marginBottom || "30px"};
  padding: 0 15px;
  /* margin-right: 10px; */
  box-shadow: ${(props) =>
    props.hasError ? "0 0 8px rgba(255, 0, 0, 0.5)" : "none"};
  transition: border 0.3s ease, box-shadow 0.3s ease;
`;

export const ErrorTextMSG = styled.div`
  color: red;
`;

export const SuccessTextMSG = styled.div`
  margin-top: 5px;
  color: #1e90ff;
`;

export const EmailCheckButton = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== "isSpecial",
})`
  ${(props) =>
    props.isSpecial
      ? `width: 100px;
  height: 45px;
  background-color: #f68063;
  border: medium none;
  color: #fff;
  cursor: not-allowed;
    pointer-events: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;`
      : ` width: 100px;
  height: 45px;
  background-color: #ebebeb;
  border: medium none;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  &:hover {
    background-color: #ffaa77;
    color: #fff;
  }
  &:disabled {
    background-color: #f2f2f2;
    color: #333;
    cursor: not-allowed;
    pointer-events: none;
  }`}
`;

export const CancelButton = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== "isSpecial",
})`
  ${(props) =>
    props.isSpecial
      ? `width: 100px;
  height: 45px;
  background-color: #f2f2f2;
  border: medium none;
  color: #fff;
  cursor: not-allowed;
    pointer-events: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;`
      : ` width: 100%;
  height: 25px;
  background-color: #f2f2f2;
  border: medium none;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  &:hover {
    background-color: #ffaa77;
    color: #fff;
  }
  &:disabled {
    background-color: #f2f2f2;
    color: #333;
    cursor: not-allowed;
    pointer-events: none;
  }`}
`;

export const MyAccountDataButton = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== "isSpecial",
})`
  ${(props) =>
    props.isSpecial
      ? `width: 100px;
  height: 45px;
  background-color: #f68063;
  border: medium none;
  color: #fff;
  cursor: not-allowed;
    pointer-events: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;`
      : ` width: 100px;
  height: 30px;
  background-color: #f2f2f2;
  border: medium none;
  margin-top: 0px;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  &:hover {
    background-color: #ffaa77;
    color: #fff;
  }
  &:disabled {
    background-color: #f2f2f2;
    color: #333;
    cursor: not-allowed;
    pointer-events: none;
  }`}
`;

export const ForgotEmailCheckButton = styled.button`
  width: 100%;
  height: 45px;
  background-color: #f2f2f2;
  border: medium none;
  color: #333;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  &:hover {
    background-color: #ffaa77;
    color: #fff;
  }
  &:disabled {
    background-color: #f2f2f2;
    color: #333;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const LoginButton = styled.button`
  width: 100%;
  height: 45px;
  background-color: #f2f2f2;
  border: medium none;
  color: #333;
  border-radius: 0px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  &:hover {
    background-color: #ffaa77;
    color: #fff;
  }
  &:disabled {
    background-color: #f2f2f2;
    color: #333;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const SingUpDivBox = styled.div`
  display: flex;
  justify-content: center;
`;

export const HeaderEmailBox = styled.div`
  font-weight: 700;
  font-size: 12px;
`;

export const HeaderSmallButtonBox = styled.div`
  font-size: 8px;
  font-weight: 400;
  cursor: pointer;
`;

export const ZumBox = styled.div`
  font-size: 12px;
`;

export const NoLoginHeaderBox = styled.div`
  border: 1px solid #ebebeb;
  width: 100%;
  padding: 20px 20px 0px 20px;
  margin: 10px;
  border-radius: 8px;
`;

export const NoLoginHeaderTextBox = styled.div`
  font-weight: 400;
  font-size: 10px;
`;

export const NoLoginHeaderErrorText = styled.div`
  color: red;
  font-weight: 400;
  font-size: 10px;
`;

export const NoSizeBox = styled.div`
  height: 24px;
`;

export const LoginModalBox = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

export const ModalCheckButton = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== "isSpecial",
})`
  ${(props) =>
    props.isSpecial
      ? `width: 100px;
  height: 45px;
  background-color: #f68063;
  border: medium none;
  color: #fff;
  cursor: not-allowed;
    pointer-events: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;`
      : ` width: 100px;
  height: 45px;
  background-color: #FFEECD;
  border: medium none;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  &:hover {
    background-color: #f68063;
    color: #fff;
  }
  &:disabled {
    background-color: #f2f2f2;
    color: #333;
    cursor: not-allowed;
    pointer-events: none;
  }`}
`;

export const ModalCheckButton2 = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== "isSpecial",
})`
  ${(props) =>
    props.isSpecial
      ? `width: 100px;
  height: 45px;
  background-color: #f68063;
  border: medium none;
  color: #fff;
  cursor: not-allowed;
    pointer-events: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;`
      : ` width: 100px;
  height: 45px;
  background-color: #ffaa77;
  border: medium none;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  &:hover {
    background-color: #f68063;
    color: #fff;
  }
  &:disabled {
    background-color: #f2f2f2;
    color: #333;
    cursor: not-allowed;
    pointer-events: none;
  }`}
`;

export const ForgetPwButton = styled.button`
  border: none;
  background-color: transparent;
  transition: color 0.3s ease;
  &:hover {
    color: #ffaa77;
  }
`;

export const PwEyesButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebebeb;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 8px;
  width: 50px;
  border-left: none;
  cursor: pointer;
  transition: border 0.3s ease, box-shadow 0.3s ease;
`;
export const LoginInputBoxWrapper = styled.div`
  display: flex;
  transition: border 0.3s ease, box-shadow 0.3s ease;

  /* LoginInputBox에 포커스가 있을 때 PwEyesButton의 테두리 색상을 변경 */
  &:focus-within ${PwEyesButton} {
    border-color: #ffaa77;
  }
`;

export const SingUpBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ebebeb;
  padding: 10px;
  cursor: pointer;
`;

export const SingUpLogoBox = styled.div`
  height: 26px;
  width: 26px;
  margin-right: 10px;
`;

export const SingUpLineText = styled.span`
  font-size: 15px;
  font-weight: 400;
`;
