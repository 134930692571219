import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getDiscountPrice } from "../../helpers/product";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { AuthVerifyEmail } from "../../api/Authentication/AuthLogin";
import { ClipLoader } from "react-spinners";
import {
  ErrorMsgText,
  FlexCenterBox,
  SizeBoxH15,
  SizeBoxH30,
  SizeBoxH5,
  SpanTextBold,
  SpanTextBoldOne,
  SpanTextBoldTwo,
  SuccessMsgText,
  TextBox,
} from "../../assets/style/Common";
import {
  EmailCheckButton,
  ForgotEmailCheckButton,
  InputBox,
} from "../../assets/style/login-register";
import { useTranslation } from "react-i18next";
import {
  ForgotEmailHandler,
  handleForgotEmail,
} from "../../handler/forgot-pw/ForgotPwHandler";
import { emailRegex } from "../../util/const";

const ForgotPw = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  let cartTotalPrice = 0;

  let { pathname } = useLocation();
  const currency = useSelector((state) => state.currency);
  const { cartItems } = useSelector((state) => state.cart);
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [isTokenOk, setTokenOk] = useState(false);

  const [isEmail, setEmail] = useState("");
  const [isEmailValueError, setEmailValueError] = useState(false);
  const [isEmailValue, setEmailValueCheck] = useState(false);
  const [isPostEmail, setPostEmail] = useState(false);
  useEffect(() => {
    if (isEmail.trim() === "") {
      setEmailValueCheck(true);
    } else if (!emailRegex.test(isEmail)) {
      setEmailValueCheck(true);
    } else {
      setEmailValueCheck(false);
    }
  }, [isEmail]);

  return (
    <Fragment>
      <SEO
        titleTemplate="Checkout"
        description="Checkout page of flone react minimalist eCommerce template."
      />
      <LayoutOne headerTop="visible">
        <div className="checkout-area pt-95 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="item-empty-area text-center">
                  <>
                    <div className="item-empty-area__text">
                      <>
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-7 col-md-12 ms-auto me-auto">
                              <div className="login-register-wrapper">
                                <div className="login-form-container">
                                  <div className="login-register-form">
                                    <form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <TextBox>{t("pw_san")}</TextBox>
                                      <FlexCenterBox>
                                        <SpanTextBoldOne>M</SpanTextBoldOne>
                                        <SpanTextBoldTwo>O</SpanTextBoldTwo>
                                        <SpanTextBoldOne>H</SpanTextBoldOne>
                                        <SpanTextBoldTwo>A</SpanTextBoldTwo>
                                        <span>{t("sing_up_email_pl")}</span>
                                        <br />
                                      </FlexCenterBox>
                                      <FlexCenterBox>
                                        <span>{t("pw_change_email")}</span>
                                      </FlexCenterBox>

                                      <SizeBoxH30></SizeBoxH30>
                                      <InputBox
                                        type="text"
                                        placeholder="이메일"
                                        disabled={isPostEmail}
                                        value={isEmail}
                                        onChange={(e) => {
                                          ForgotEmailHandler(e, setEmail);
                                        }}
                                        hasError={isEmailValueError}
                                        marginBottom="0px"
                                      />
                                      <SizeBoxH5 />
                                      {isEmailValueError === true && (
                                        <ErrorMsgText>
                                          MOHA {t("no_members")}
                                        </ErrorMsgText>
                                      )}
                                      {isPostEmail === true && (
                                        <SuccessMsgText>
                                          {t("go_email")}
                                        </SuccessMsgText>
                                      )}

                                      <SizeBoxH15 />
                                      <div>
                                        {isLoading === true ? (
                                          <FlexCenterBox>
                                            <ClipLoader
                                              color="#F68063"
                                              size={50}
                                            />
                                          </FlexCenterBox>
                                        ) : (
                                          <ForgotEmailCheckButton
                                            onClick={() =>
                                              handleForgotEmail(
                                                isEmail,
                                                setEmailValueError,
                                                setEmailValueCheck,
                                                setPostEmail,
                                                setLoading
                                              )
                                            }
                                            disabled={isEmailValue}
                                            type="submit"
                                          >
                                            {isPostEmail === true ? (
                                              <span> {t("go_email")}</span>
                                            ) : (
                                              <span>{t("re_pw_change")}</span>
                                            )}
                                          </ForgotEmailCheckButton>
                                        )}
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default ForgotPw;
