import { Orders } from "../../api/orders/GetOrders";
import {
  setOrderListData,
  setOrderListPageData,
} from "../../store/action/OrderListAction";
import { removeCookie } from "../../util/Cookie";
import { TokenName } from "../../util/const";

export const RestAddressHandler = (e, setRest) => {
  setRest(e.target.value);
};

export const PhonNumberHandler = (e, setPhonNumber) => {
  const value = e.target.value;
  const numericValue = value.replace(/[^0-9]/g, ""); // 숫자가 아닌 문자는 제거
  setPhonNumber(numericValue);
};

export const EmailHandler = (e, setEmail) => {
  setEmail(e.target.value);
};

export const NameHandler = (e, setName) => {
  setName(e.target.value);
};

export const InfoMessageHandler = (e, setInfo) => {
  setInfo(e.target.value);
};

export const handleOpenPaymentPage = (
  address,
  isName,
  isPhoneNumber,
  isEmail,
  isInfo,
  cartTotalPrice,
  isRest,
  setAddressError,
  setRestError,
  setIsWindowOpen,
  isCart
) => {
  /// 유효성 검사

  let pass = true;

  if (address.fullAddress.trim() === "") {
    setAddressError(true);
    pass = false;
  }

  if (address.zoneCode.trim() === "") {
    setAddressError(true);
    pass = false;
  }

  if (isRest.trim() === "") {
    setRestError(true);
    pass = false;
  }

  if (pass === true) {
    setAddressError(false);
    setRestError(false);
  }

  if (pass === false) {
    return;
  }

  const paymentUrl = `${window.location.origin}/payment`;

  const queryParams = new URLSearchParams({
    fullAddress: address.fullAddress,
    zoneCode: address.zoneCode,
    name: isName,
    phoneNumber: isPhoneNumber,
    email: isEmail,
    info: isInfo,
    cartTotalPrice: cartTotalPrice,
    isCart:isCart
  }).toString();

  const urlWithParams = `${paymentUrl}?${queryParams}`;

  const newWindow = window.open(
    urlWithParams,
    "_blank",
    "width=530,height=730"
  );

  if (newWindow) {
    setIsWindowOpen(true);

    const checkWindowClosed = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(checkWindowClosed);
        setIsWindowOpen(false);
      }
    }, 1000);
  }
};

export const fetchOrders = async (
  setLoading,
  navigate,
  dispatch,
  page,
  limit
) => {
  setLoading(true);
  try {
    const resp = await Orders({ page, limit });
    if (resp.msg === "Success") {
      dispatch(setOrderListData(resp.data.data));
      const { page, size, total, total_pages } = resp.data.data.meta;

      dispatch(setOrderListPageData({ page, size, total, total_pages }));
    }

    if (resp.msg === "Fail") {
      console.log("가져오기 실패", resp);
      removeCookie(TokenName);
      navigate("/");
    }
  } catch (error) {
    console.log("알수 없는 에러", error);
  } finally {
    setLoading(false);
  }
};
