// export const Footer

import styled from "styled-components";

export const FooterBox = styled.div`
  //   background-color: #ffaa77;
  display: flex;
  min-height: 10vh;
`;

export const FooterXxlBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding-top: 20px;
`;

export const FooterFlexBox = styled.div`
  flex: 1;
  display: #5d5d5d;
  color: black;
  font-size: 10px;
  font-weight: 600;
`;

export const FooterFlexFlex = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  /* flex-direction: column; */
  text-align: right;
  font-size: 10px;
  font-weight: 600;
  color: #5d5d5d;
`;

export const FooterDivBox = styled.div`
  width: 10rem;
`;

export const FooterImg = styled.img`
  filter: invert(1);
  width: 100%;
`;

export const FooterTextAlign = styled.div`
  text-align: start;
`;

export const FooterMdBox = styled.div`
  height: 3.7rem;
`;

export const FooterDivFlexBox = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;
  margin-bottom: 5px;
`;

export const FooterPointerBox = styled.div`
  cursor: pointer;
  font-size: 15px;
  font-weight: 800;
  transition: color 0.35s ease;

  &:hover {
    color: #ffaa77;
  }
`;

export const FooterWMSvg = styled.svg`
  width: 10px;
  margin-right: 10px;
`;
