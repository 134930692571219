import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { Table, Td, Th } from "../../assets/style/info";

const ConditionEn = () => {
  let { pathname } = useLocation();

  return (
    <Fragment>
      <SEO
        titleTemplate="Not Found"
        description="404 of flone react minimalist eCommerce template."
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        {/* <Breadcrumb 
          pages={[
            {label: "Home", path: process.env.PUBLIC_URL + "/" },
            {label: "error page", path: process.env.PUBLIC_URL + pathname }
          ]} 
        /> */}
        <div className="error-area pt-40 pb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 ">
                <div>
                  <h1>MOHA Terms of Service</h1>

                  <h2>1. Purpose</h2>
                  <p>
                    These terms and conditions establish the rights,
                    obligations, and responsibilities between the cyber mall
                    (hereinafter referred to as the "Mall") operated by XRAI
                    Co., Ltd. (e-commerce business operator) and users regarding
                    the use of internet-related services (hereinafter referred
                    to as "Services") provided by the Mall.
                    <br />※ These terms and conditions also apply to e-commerce
                    using PC communications, wireless communications, etc.,
                    unless contrary to their nature.
                  </p>
                  <h2>2. Definitions</h2>
                  <ul>
                    <li>
                      "Mall" refers to a virtual business establishment set up
                      by XRAI Co., Ltd. using information and communications
                      equipment such as computers to provide goods or services
                      (hereinafter referred to as "goods, etc.") to users, and
                      also refers to the cyber mall operator.
                    </li>
                    <li>
                      "User" refers to members and non-members who access the
                      "Mall" and receive services provided by the "Mall" in
                      accordance with these terms and conditions.
                    </li>
                    <li>
                      'Member' refers to a person who has registered with the
                      "Mall" and can continuously use the services provided by
                      the "Mall".
                    </li>
                    <li>
                      'Non-member' refers to a person who uses the services
                      provided by the "Mall" without registering for membership.
                    </li>
                  </ul>

                  <h2>
                    3. Display, Explanation, and Revision of Terms and
                    Conditions
                  </h2>
                  <ol>
                    <li>
                      The "Mall" shall post these terms and conditions, company
                      name, representative's name, business location address
                      (including address for handling consumer complaints),
                      phone number, fax number, email address, business
                      registration number, telecommunications sales business
                      report number, personal information manager, etc., where
                      users can easily see them on the initial service screen of
                      the cyber mall. However, the content of the terms and
                      conditions can be made available to users through a linked
                      screen.
                    </li>
                    <li>
                      Before users agree to the terms and conditions, the "Mall"
                      must obtain user confirmation through a separate linked
                      screen or pop-up screen for important contents such as
                      withdrawal of subscription, delivery responsibility,
                      refund conditions, etc., specified in the terms and
                      conditions.
                    </li>
                    <li>
                      The "Mall" may revise these terms and conditions within
                      the scope that does not violate related laws such as the
                      "Act on Consumer Protection in Electronic Commerce," "Act
                      on Regulation of Terms and Conditions," "Framework Act on
                      Electronic Documents and Electronic Commerce," "Electronic
                      Financial Transactions Act," "Electronic Signature Act,"
                      "Act on Promotion of Information and Communications
                      Network Utilization and Information Protection,"
                      "Door-to-Door Sales Act," and "Framework Act on
                      Consumers."
                    </li>
                    <li>
                      When revising the terms and conditions, the "Mall" shall
                      specify the application date and reasons for revision and
                      post them along with the current terms and conditions on
                      the Mall's initial screen from 7 days before the
                      application date until the day before application.
                      However, when changing terms and conditions unfavorably to
                      users, it shall give at least 30 days advance notice
                      period. In this case, the "Mall" shall clearly compare and
                      display the pre-revision and post-revision contents for
                      users to understand easily.
                    </li>
                    <li>
                      When the "Mall" revises the terms and conditions, the
                      revised terms and conditions apply only to contracts
                      concluded after the application date, and the previous
                      terms and conditions apply to contracts already concluded
                      before that date. However, if a user who has already
                      concluded a contract sends their intention to be subject
                      to the revised terms and conditions to the "Mall" within
                      the notice period of the revised terms and conditions
                      under Paragraph 3 and receives the "Mall's" consent, the
                      revised terms and conditions shall apply.
                    </li>
                    <li>
                      Matters not specified in these terms and conditions and
                      the interpretation of these terms and conditions shall be
                      governed by the Act on Consumer Protection in Electronic
                      Commerce, the Act on Regulation of Terms and Conditions,
                      the Consumer Protection Guidelines in Electronic Commerce
                      established by the Fair Trade Commission, and related laws
                      or commercial practices.
                    </li>
                  </ol>

                  <h2>4. Provision and Change of Services</h2>
                  <ol>
                    <li>
                      The "Mall" performs the following tasks:
                      <ul>
                        <li>
                          Provision of information about goods or services and
                          conclusion of purchase contracts
                        </li>
                        <li>
                          Delivery of goods or services for which purchase
                          contracts have been concluded
                        </li>
                        <li>Other tasks determined by the "Mall"</li>
                      </ul>
                    </li>
                    <li>
                      The "Mall" may change the contents of goods or services to
                      be provided by future contracts in cases of sold-out goods
                      or services or changes in technical specifications. In
                      this case, the changed contents and provision date of the
                      goods or services shall be immediately posted where the
                      current contents of the goods or services are posted.
                    </li>
                    <li>
                      If the "Mall" needs to change the contents of services
                      contracted with users due to sold-out goods or services or
                      changes in technical specifications, it shall immediately
                      notify the reason to an address where notification is
                      possible.
                    </li>
                    <li>
                      In the case of the preceding paragraph, the "Mall" shall
                      compensate for damages suffered by users. However, this
                      shall not apply if the "Mall" proves that there was no
                      intention or negligence.
                    </li>
                  </ol>

                  <h2>5. Suspension of Service</h2>
                  <ol>
                    <li>
                      The "Mall" may temporarily suspend service provision in
                      cases of maintenance inspection, replacement and failure
                      of information and communication facilities, interruption
                      of communications, etc.
                    </li>
                    <li>
                      The "Mall" shall compensate for damages suffered by users
                      or third parties due to temporary suspension of service
                      provision for reasons in Paragraph 1. However, this shall
                      not apply if the "Mall" proves that there was no intention
                      or negligence.
                    </li>
                    <li>
                      If services cannot be provided due to business conversion,
                      business abandonment, merger between companies, etc., the
                      "Mall" shall notify users in the method specified in
                      Article 8 and compensate consumers according to the
                      conditions initially presented by the "Mall". However, if
                      the "Mall" has not notified compensation standards, etc.,
                      mileage or accumulated points of users shall be paid to
                      users in kind or cash corresponding to the currency value
                      used in the "Mall".
                    </li>
                  </ol>

                  <h2>6. Membership Registration</h2>
                  <ol>
                    <li>
                      Users apply for membership registration by filling in
                      member information according to the registration form set
                      by the "Mall" and expressing their intention to agree to
                      these terms and conditions.
                    </li>
                    <li>
                      The "Mall" shall register as members those who apply for
                      membership as in Paragraph 1 unless they fall under any of
                      the following:
                      <ul>
                        <li>
                          Case where the applicant has previously lost
                          membership qualification under Article 7, Paragraph 3,
                          except when 3 years have passed since the loss of
                          membership qualification and approval for
                          re-registration has been obtained from the "Mall"
                        </li>
                        <li>
                          Case where there are false, omissions, or errors in
                          registration details
                        </li>
                        <li>
                          Case where registration as a member is deemed
                          technically significantly hindering to the "Mall"
                        </li>
                      </ul>
                    </li>
                    <li>
                      The time of establishment of membership registration
                      contract shall be when the "Mall's" approval reaches the
                      member.
                    </li>
                    <li>
                      In case of changes to matters registered at the time of
                      membership registration, members shall notify the "Mall"
                      of such changes through member information modification
                      within a reasonable period.
                    </li>
                  </ol>

                  <h2>
                    7. Withdrawal from Membership and Loss of Qualification
                  </h2>
                  <ol>
                    <li>
                      Members may request withdrawal from the "Mall" at any
                      time, and the "Mall" shall immediately process the
                      membership withdrawal.
                    </li>
                    <li>
                      The "Mall" may restrict or suspend membership
                      qualification if members fall under any of the following
                      cases:
                      <ul>
                        <li>
                          Case where false information was registered when
                          applying for registration
                        </li>
                        <li>
                          Case where payment obligations such as payment for
                          goods purchased using the "Mall" or other obligations
                          related to using the "Mall" are not fulfilled by the
                          due date
                        </li>
                        <li>
                          Case where the use of the "Mall" by others is
                          interfered with or their information is stolen,
                          threatening the order of electronic commerce
                        </li>
                        <li>
                          Case where acts prohibited by laws or these terms and
                          conditions are performed or acts contrary to public
                          order and morals are performed using the "Mall"
                        </li>
                      </ul>
                    </li>
                    <li>
                      After restricting or suspending membership qualification,
                      if the same act is repeated twice or more or the reason is
                      not corrected within 30 days, the "Mall" may revoke
                      membership qualification.
                    </li>
                    <li>
                      When the "Mall" revokes membership qualification, it shall
                      delete the member registration. In this case, it shall
                      notify the member and give at least 30 days to explain
                      before deleting the member registration.
                    </li>
                  </ol>

                  <h2>8. Notification to Members</h2>
                  <ol>
                    <li>
                      When the "Mall" notifies members, it can do so through the
                      email address previously agreed upon and designated by the
                      member.
                    </li>
                    <li>
                      For notifications to unspecified multiple members, the
                      "Mall" may substitute individual notifications by posting
                      on the "Mall's" bulletin board for at least one week.
                      However, individual notifications shall be made for
                      matters that significantly affect the member's own
                      transactions.
                    </li>
                  </ol>
                  <h2>
                    9. Purchase Request and Consent to Provide Personal
                    Information
                  </h2>
                  <ol>
                    <li>
                      Users of the "Mall" shall request purchases through the
                      following or similar methods, and the "Mall" shall provide
                      the following information in an easily understandable
                      manner when users make purchase requests:
                      <ol>
                        <li>Search and selection of goods</li>
                        <li>
                          Input of recipient's name, address, phone number,
                          email address (or mobile phone number)
                        </li>
                        <li>
                          Confirmation of terms and conditions, services with
                          restricted right of withdrawal, and costs such as
                          shipping fees and installation fees
                        </li>
                        <li>
                          Indication of agreement to these terms and conditions
                          and confirmation or rejection of items in point 3
                          (e.g., mouse click)
                        </li>
                        <li>
                          Purchase request for goods and confirmation thereof,
                          or consent to the "Mall's" confirmation
                        </li>
                        <li>Selection of payment method</li>
                      </ol>
                    </li>
                    <li>
                      When the "Mall" needs to provide buyer's personal
                      information to third parties, it must inform and obtain
                      consent from the buyer regarding:
                      <ol>
                        <li>The recipient of personal information</li>
                        <li>
                          The purpose of use of personal information by the
                          recipient
                        </li>
                        <li>Items of personal information to be provided</li>
                        <li>
                          The recipient's period of retention and use of
                          personal information
                        </li>
                      </ol>
                      (This also applies when there are changes to matters for
                      which consent was obtained.)
                    </li>
                    <li>
                      When the "Mall" outsources the handling of buyer's
                      personal information to third parties, it must inform and
                      obtain consent from the buyer regarding:
                      <ol>
                        <li>
                          The person receiving the outsourcing of personal
                          information handling
                        </li>
                        <li>
                          The content of the outsourced personal information
                          handling work
                        </li>
                      </ol>
                      (This also applies when there are changes to matters for
                      which consent was obtained.) However, when it is necessary
                      for the fulfillment of service provision contracts and
                      related to improving buyer convenience, notification
                      through privacy policy as prescribed by the "Act on
                      Promotion of Information and Communications Network
                      Utilization and Information Protection" may substitute for
                      notification and consent procedures.
                    </li>
                  </ol>

                  <h2>10. Formation of Contract</h2>
                  <ol>
                    <li>
                      The "Mall" may not accept purchase requests as in Article
                      9 if they fall under any of the following cases. However,
                      when concluding contracts with minors, it must notify that
                      the minor or their legal representative can cancel the
                      contract if consent from the legal representative is not
                      obtained:
                      <ol>
                        <li>
                          Case where there are false statements, omissions, or
                          errors in the application
                        </li>
                        <li>
                          Case where a minor purchases goods and services
                          prohibited by the Youth Protection Act such as tobacco
                          and alcohol
                        </li>
                        <li>
                          Case where acceptance of the purchase request is
                          deemed technically significantly hindering to the
                          "Mall"
                        </li>
                        <li>
                          Case where the purchase request is made using a false
                          name or another person's name
                        </li>
                        <li>
                          Case where the service is intended to be used for
                          improper purposes
                        </li>
                        <li>
                          Case where the purchase request violates other
                          prescribed general matters
                        </li>
                      </ol>
                    </li>
                    <li>
                      The contract shall be deemed formed when the "Mall's"
                      acceptance reaches the user in the form of receipt
                      confirmation notice under Article 12, Paragraph 1.
                    </li>
                    <li>
                      The "Mall's" expression of acceptance shall include
                      information about confirmation of user's purchase request,
                      availability for sale, correction and cancellation of
                      purchase request, etc.
                    </li>
                    <li>
                      The "Mall" may withhold or reject acceptance of purchase
                      and service use requests until acceptance restriction
                      reasons are resolved in the following cases:
                      <ol>
                        <li>
                          When there are technical difficulties with the "Mall"
                        </li>
                        <li>
                          When acceptance of use is difficult due to reasons
                          attributable to the "Mall"
                        </li>
                      </ol>
                    </li>
                  </ol>

                  <h2>11. Payment Methods</h2>
                  <p>
                    Payment for goods or services purchased from the "Mall" can
                    be made through any available method among the following.
                    However, the "Mall" cannot collect any additional fees under
                    any name for the payment method chosen by users:
                  </p>
                  <ul>
                    <li>
                      Various account transfers such as phone banking, internet
                      banking, mail banking
                    </li>
                    <li>
                      Various card payments such as prepaid cards, debit cards,
                      credit cards
                    </li>
                    <li>Online bank deposit</li>
                    <li>Payment by electronic money</li>
                    <li>Payment upon receipt</li>
                    <li>
                      Payment by points such as mileage issued by the "Mall"
                    </li>
                    <li>
                      Payment by gift certificates contracted with or recognized
                      by the "Mall"
                    </li>
                    <li>Payment by other electronic payment methods</li>
                  </ul>

                  <h2>
                    12. Receipt Confirmation Notice, Change and Cancellation of
                    Purchase Request
                  </h2>
                  <ol>
                    <li>
                      The "Mall" shall send a receipt confirmation notice to
                      users when there is a purchase request.
                    </li>
                    <li>
                      Users who receive the receipt confirmation notice can
                      request changes or cancellation of the purchase request
                      immediately after receiving the notice if there is any
                      discrepancy in the expression of intention, and the "Mall"
                      shall process such requests without delay if received
                      before shipping. However, if payment has already been
                      made, it shall be governed by the provisions on withdrawal
                      of subscription in Article 15.
                    </li>
                  </ol>

                  <h2>13. Supply of Goods</h2>
                  <ol>
                    <li>
                      Unless there is a separate agreement regarding the timing
                      of supply of goods with users, the "Mall" shall take
                      necessary measures such as custom production and packaging
                      to enable shipping of goods within 7 days from the date of
                      subscription. However, when the "Mall" has already
                      received all or part of the payment for goods, it shall
                      take measures within 3 business days from the date of
                      receiving the payment. In this case, the "Mall" shall take
                      appropriate measures to allow users to check the supply
                      procedure and progress of goods.
                    </li>
                    <li>
                      The "Mall" shall specify the means of delivery, bearer of
                      delivery costs by means, and delivery period by means for
                      goods purchased by users. If the "Mall" exceeds the agreed
                      delivery period, it shall compensate for damages to users.
                      However, this shall not apply if the "Mall" proves that
                      there was no intention or negligence.
                    </li>
                  </ol>

                  <h2>14. Refund</h2>
                  <p>
                    When the "Mall" cannot deliver or provide goods requested by
                    users due to reasons such as being out of stock, it shall
                    notify users of the reason without delay and refund or take
                    necessary measures for refund within 3 business days from
                    the date of receiving payment if payment was received in
                    advance.
                  </p>

                  <h2>15. Withdrawal of Subscription</h2>
                  <ol>
                    <li>
                      Users who have concluded contracts for purchase of goods
                      with the "Mall" may withdraw their subscription within 7
                      days from the date of receiving the written contract
                      contents pursuant to Article 13, Paragraph 2 of the "Act
                      on Consumer Protection in Electronic Commerce" (referring
                      to the date of receiving goods or the date when supply of
                      goods began if the supply of goods occurred later than
                      receiving the written document). However, if there are
                      different provisions regarding withdrawal of subscription
                      in the "Act on Consumer Protection in Electronic
                      Commerce," those provisions shall apply.
                    </li>
                    <li>
                      Users cannot return or exchange goods in any of the
                      following cases after receiving them:
                      <ol>
                        <li>
                          When goods are destroyed or damaged due to reasons
                          attributable to users (however, withdrawal of
                          subscription is possible if packaging was damaged to
                          check the contents of goods)
                        </li>
                        <li>
                          When the value of goods has significantly decreased
                          due to use or partial consumption by users
                        </li>
                        <li>
                          When the value of goods has significantly decreased
                          making resale difficult due to the passage of time
                        </li>
                        <li>
                          When the packaging of original goods that can be
                          reproduced with goods of the same performance is
                          damaged
                        </li>
                      </ol>
                    </li>
                    <li>
                      Despite cases 2 through 4 of Paragraph 2, if the "Mall"
                      did not clearly indicate that withdrawal of subscription
                      would be restricted in a place easily recognizable by
                      consumers or did not provide trial products, users'
                      withdrawal of subscription shall not be restricted.
                    </li>
                    <li>
                      Notwithstanding Paragraphs 1 and 2, users can withdraw
                      subscription within 3 months from the date of receiving
                      goods, or within 30 days from the date they knew or could
                      have known the fact, if the contents of goods differ from
                      display/advertisement contents or if the contract was
                      performed differently from its contents.
                    </li>
                  </ol>
                  <h2>16. Effects of Withdrawal of Subscription</h2>
                  <p>
                    ① When the "Mall" receives returned goods from users, it
                    shall refund the payment already received for the goods
                    within 3 business days. In this case, if the "Mall" delays
                    the refund to users, it shall pay delay interest calculated
                    by multiplying the delay interest rate prescribed in Article
                    21-2 of the Enforcement Decree of the "Act on Consumer
                    Protection in Electronic Commerce" for the delay period.
                  </p>
                  <p>
                    ② When making the above refund, if users paid for goods
                    using credit cards or electronic money, the "Mall" shall
                    request the business operator who provided the payment
                    method to stop or cancel the payment claim for the goods
                    without delay.
                  </p>
                  <p>
                    ③ In case of withdrawal of subscription, users shall bear
                    the costs necessary for returning the received goods. The
                    "Mall" shall not claim penalties or damages from users due
                    to withdrawal of subscription. However, if withdrawal of
                    subscription is made because the contents of goods differ
                    from display/advertisement contents or the contract was
                    performed differently, the "Mall" shall bear the costs
                    necessary for returning the goods.
                  </p>
                  <p>
                    ④ If users paid shipping fees when receiving goods, the
                    "Mall" shall clearly indicate who bears those costs in case
                    of withdrawal of subscription in a way that users can easily
                    understand.
                  </p>
                  <h2>17. Protection of Personal Information</h2>
                  <p>
                    ① The "Mall" collects minimum personal information necessary
                    for service provision. The following items are collected as
                    required information, while other items are optional:
                  </p>
                  <ul>
                    <li>Name</li>
                    <li>
                      Resident registration number (for members) or foreign
                      registration number
                    </li>
                    <li>Address</li>
                    <li>Phone number</li>
                    <li>ID (for members)</li>
                    <li>Password (for members)</li>
                    <li>Email address</li>
                  </ul>
                  <p>
                    ② The "Mall" must obtain consent from users when collecting
                    personally identifiable information.
                  </p>
                  <p>
                    ③ The "Mall" cannot use personal information provided by
                    users for purposes other than agreed upon or provide it to
                    third parties without consent, and the "Mall" is responsible
                    for this. However, the following cases are exceptions:
                  </p>
                  <ol>
                    <li>
                      When providing minimum user information (name, address,
                      phone number) necessary for delivery to shipping companies
                    </li>
                    <li>
                      When providing to hosting companies for member management,
                      system management, and information provision
                    </li>
                    <li>
                      When providing in a form that cannot identify specific
                      individuals for statistics, academic research, or market
                      research
                    </li>
                    <li>
                      When necessary for payment settlement related to
                      transactions
                    </li>
                    <li>
                      When identity verification is needed to prevent
                      impersonation or fraud
                    </li>
                    <li>
                      When required by law or when there are unavoidable reasons
                      under law
                    </li>
                  </ol>
                  <p>
                    ④ When the "Mall" needs to obtain user consent under
                    Paragraphs 2 and 3, it must specify or notify in advance the
                    identity of the personal information manager (affiliation,
                    name, phone number, other contact information), purpose of
                    collection and use, and matters related to third-party
                    information provision (recipient, purpose, and content of
                    information to be provided) as stipulated in Article 22,
                    Paragraph 2 of the "Act on Promotion of Information and
                    Communications Network Utilization and Information
                    Protection," and users may withdraw this consent at any
                    time.
                  </p>
                  <p>
                    ⑤ Users may request access to and correction of errors in
                    their personal information held by the "Mall" at any time,
                    and the "Mall" has the obligation to take necessary measures
                    without delay. When users request error correction, the
                    "Mall" shall not use the personal information until the
                    error is corrected.
                  </p>
                  <p>
                    ⑥ The "Mall" must minimize the number of people handling
                    users' personal information for protection and is
                    responsible for all damages to users caused by loss, theft,
                    leakage, unauthorized third-party provision, or alteration
                    of users' personal information, including credit cards and
                    bank accounts.
                  </p>
                  <p>
                    ⑦ The "Mall" or third parties who received personal
                    information shall destroy the personal information without
                    delay when the purpose of collection or provision is
                    achieved:
                  </p>
                  <ul>
                    <li>
                      Membership information: When membership is withdrawn or
                      terminated
                    </li>
                    <li>
                      Payment information: When payment is completed or the
                      statute of limitations expires
                    </li>
                    <li>
                      Delivery information: When goods or services are delivered
                      or provided
                    </li>
                    <li>
                      Information collected for surveys, events, etc.: When the
                      survey or event ends
                    </li>
                  </ul>
                  <p>
                    However, personal information may be retained even after
                    achieving the purpose of collection or provision if
                    preservation is necessary under related laws such as the
                    Commercial Act:
                  </p>
                  <ul>
                    <li>
                      Records on contracts or withdrawal of subscription: 5
                      years
                    </li>
                    <li>Records on payment and supply of goods: 5 years</li>
                    <li>
                      Records on consumer complaints or dispute handling: 3
                      years
                    </li>
                  </ul>
                  <p>
                    ⑧ The "Mall" shall not preset consent boxes for collection,
                    use, and provision of personal information. Additionally, it
                    shall specifically indicate services that are restricted
                    when users refuse to consent to collection, use, and
                    provision of personal information, and shall not restrict or
                    refuse service provision such as membership registration due
                    to users' refusal to consent to collection, use, and
                    provision of non-essential personal information.
                  </p>

                  <h2>18. Obligations of the "Mall"</h2>
                  <p>
                    ① The "Mall" shall not engage in acts prohibited by laws and
                    these terms or contrary to public order and morals, and
                    shall do its best to provide goods and services continuously
                    and reliably in accordance with these terms.
                  </p>
                  <p>
                    ② The "Mall" must have security systems in place to protect
                    users' personal information (including credit information)
                    so that users can safely use internet services.
                  </p>
                  <p>
                    ③ The "Mall" is responsible for compensating damages if
                    users suffer losses due to unfair display or advertising
                    practices under Article 3 of the "Act on Fair Labeling and
                    Advertising."
                  </p>
                  <p>
                    ④ The "Mall" shall not send commercial advertising emails
                    that users do not want.
                  </p>

                  <h2>19. Obligations Regarding Member ID and Password</h2>
                  <p>
                    ① Members are responsible for managing their ID and password
                    except in cases under Article 17.
                  </p>
                  <p>
                    ② Members shall not allow third parties to use their ID and
                    password.
                  </p>
                  <p>
                    ③ When members become aware that their ID and password have
                    been stolen or are being used by third parties, they must
                    immediately notify the "Mall" and follow the "Mall's"
                    guidance if provided.
                  </p>

                  <h2>20. User Obligations</h2>
                  <p>Users shall not engage in the following acts:</p>
                  <ul>
                    <li>
                      Registering false information when applying or making
                      changes
                    </li>
                    <li>Stealing others' information</li>
                    <li>Modifying information posted on the "Mall"</li>
                    <li>
                      Transmitting or posting information (such as computer
                      programs) other than that specified by the "Mall"
                    </li>
                    <li>
                      Infringing on copyrights and other intellectual property
                      rights of the "Mall" or third parties
                    </li>
                    <li>
                      Damaging the reputation of or interfering with the
                      business of the "Mall" or third parties
                    </li>
                    <li>
                      Publicly disclosing or posting obscene or violent
                      messages, images, sounds, or other information contrary to
                      public order and morals on the "Mall"
                    </li>
                  </ul>

                  <h2>
                    21. Relationship Between Linked "Mall" and Linked-to "Mall"
                  </h2>
                  <p>
                    ① When upper and lower "Malls" are connected through
                    hyperlinks (including text, images, and animations), the
                    former is called the linking "Mall" (website) and the latter
                    is called the linked-to "Mall" (website).
                  </p>
                  <p>
                    ② The linking "Mall" is not responsible for guaranteeing
                    transactions between users and the linked-to "Mall"
                    regarding goods independently provided by the linked-to
                    "Mall" if this is specified on the initial screen of the
                    linking "Mall" or in a pop-up screen at the time of
                    connection.
                  </p>

                  <h2>22. Attribution and Restriction of Copyright</h2>
                  <p>
                    ① Copyright and other intellectual property rights for works
                    created by the "Mall" belong to the "Mall."
                  </p>
                  <p>
                    ② Users shall not use information obtained through using the
                    "Mall" that is subject to the "Mall's" intellectual property
                    rights for commercial purposes or allow third parties to use
                    it through reproduction, transmission, publication,
                    distribution, broadcasting, or other methods without prior
                    consent from the "Mall."
                  </p>
                  <p>
                    ③ The "Mall" must notify users when using copyrights
                    belonging to users according to agreement.
                  </p>

                  <h2>23. Dispute Resolution</h2>
                  <p>
                    ① The "Mall" shall establish and operate a damage
                    compensation processing organization to reflect legitimate
                    opinions or complaints raised by users and process
                    compensation for damages.
                  </p>
                  <p>
                    ② The "Mall" shall prioritize handling complaints and
                    opinions submitted by users. However, when prompt handling
                    is difficult, the "Mall" shall immediately notify users of
                    the reason and processing schedule.
                  </p>
                  <p>
                    ③ When users request relief for damages related to
                    e-commerce disputes between the "Mall" and users, the matter
                    may be mediated by dispute mediation institutions
                    commissioned by the Fair Trade Commission or city/provincial
                    governors.
                  </p>
                  <h3>24. Disclaimer</h3>
                  <ol>
                    <li>
                      The "Mall" is exempt from responsibility for service
                      provision when unable to provide services due to force
                      majeure such as natural disasters, war, and other similar
                      circumstances.
                    </li>
                    <li>
                      The "Mall" is exempt from responsibility when damages
                      occur due to telecommunications service providers stopping
                      or not properly providing telecommunications services.
                    </li>
                    <li>
                      The "Mall" is exempt from responsibility for damages
                      caused by unavoidable reasons such as maintenance,
                      replacement, regular inspection, or construction of
                      service facilities.
                    </li>
                    <li>
                      The "Mall" is not responsible for service use obstacles or
                      damages caused by users' fault.
                    </li>
                    <li>
                      The "Mall" is not responsible for damages caused by errors
                      in users' computers, devices, etc., or damages caused by
                      members providing incomplete personal information and
                      email addresses.
                    </li>
                    <li>
                      The "Mall" is not responsible for users' failure to obtain
                      or loss of expected profits from using the service.
                    </li>
                    <li>
                      The "Mall" is not responsible for damages caused by
                      materials obtained by users while using the service.
                      Additionally, the "Mall" is not responsible for
                      compensating psychological damage suffered by users due to
                      other users while using the service.
                    </li>
                    <li>
                      The "Mall" has no obligation to intervene in disputes
                      arising between users or between users and third parties
                      through the service, and is not responsible for
                      compensating any resulting damages.
                    </li>
                  </ol>

                  <h3>25. Jurisdiction and Governing Law</h3>
                  <ol>
                    <li>
                      Lawsuits related to e-commerce disputes between the "Mall"
                      and users shall be under the exclusive jurisdiction of the
                      district court having jurisdiction over the user's address
                      at the time of filing, or residence if there is no
                      address. However, if the user's address or residence is
                      unclear at the time of filing or the user is a foreign
                      resident, the lawsuit shall be filed with the court having
                      jurisdiction under the Civil Procedure Act.
                    </li>
                    <li>
                      Lawsuits between the company and users shall be governed
                      by the laws of the Republic of Korea.
                    </li>
                    <li>
                      Matters not specified in these terms shall be governed by
                      related laws such as the Telecommunications Business Act
                      and commercial practices.
                    </li>
                  </ol>

                  <h3>Addendum</h3>
                  <p>These Terms of Use are effective from December 2, 2024.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default ConditionEn;
