import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import clsx from "clsx";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import IconGroup from "../../components/header/IconGroup";
import MobileMenu from "../../components/header/MobileMenu";
import HeaderTop from "../../components/header/HeaderTop";
import { getCookie } from "../../util/Cookie";
import { TokenName } from "../../util/const";
import { useSelector } from "react-redux";
import {
  HeaderLogoBox,
  HeaderLogoImg,
  HeaderLogoLinkBox,
  ImgBox,
  ImgBox2,
} from "../../assets/style/Common";
import { Link } from "react-router-dom";

const HeaderOne = ({
  layout,
  top,
  borderStyle,
  headerPaddingClass,
  headerPositionClass,
  headerBgClass,
}) => {
  const tokenCheck = useSelector((state) => state.tokenState);

  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [myCookie, setMyCookie] = useState(getCookie(TokenName))
  const [cookieValue, setCookieValue] = useState(false);

  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // console.log(myCookie)
    // if (tokenCheck.isTokenSet) {
      // const myCookie = getCookie(TokenName);
      if (tokenCheck.isTokenSet) {
        setCookieValue(true);
      } else {
        setCookieValue(false);
      }
    // }
    // if (!tokenCheck.isTokenSet) {
    //   const timer = setTimeout(() => {
    //     const myCookie = getCookie(TokenName);
    //     if (myCookie) {
    //       setCookieValue(true);
    //     } else {
    //       setCookieValue(false);
    //     }
    //   }, 300);

    //   return () => clearTimeout(timer);
    // }
  }, [myCookie,tokenCheck]);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <header
      className={clsx(
        "header-area clearfix",
        headerBgClass,
        headerPositionClass
      )}
    >
      <div
        className={clsx(
          "header-top-area",
          headerPaddingClass,
          top === "visible" ? "d-none d-lg-block" : "d-none",
          borderStyle === "fluid-border" && "border-none"
        )}
      >
        <div className={layout === "container-fluid" ? layout : "container"}>
          {/* header top */}
          <HeaderTop borderStyle={borderStyle} />
        </div>
      </div>

      <div
        className={clsx(
          headerPaddingClass,
          "sticky-bar header-res-padding clearfix",
          scroll > headerTop && "stick"
        )}
      >
        <div className={layout === "container-fluid" ? layout : "container"}>
          <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-6 col-4">
              {/* header logo */}

              <HeaderLogoBox>
                <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                  <HeaderLogoLinkBox>
                    <HeaderLogoImg src="/assets/img/logo/logo.png" />
                  </HeaderLogoLinkBox>
                </Link>

                {/* <Logo imageUrl="/assets/img/logo/logo.png" logoClass="logo" /> */}
              </HeaderLogoBox>
            </div>
            {/* <div className="col-xl-8 col-lg-8 d-none d-lg-block"> */}
            {/* Nav menu */}
            {/* <NavMenu cookieValue={cookieValue} />
            </div> */}
            <div
              className="col-xl-10 col-lg-10 col-md-6 col-8"
              style={{
                paddingBottom: `30px`,
              }}
            >
              {/* Icon group */}
              <IconGroup cookieValue={cookieValue} />
            </div>
          </div>
        </div>
        {/* mobile menu */}
        <MobileMenu />
      </div>
    </header>
  );
};

HeaderOne.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string,
};

export default HeaderOne;
