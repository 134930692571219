import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo/logo.png";
import {
  ImageContainer,
  OrderCancelButton,
  OrderDayText,
  OrderDetailSpan,
  OrderListTableBodyBox,
  OrderListTd,
  OrderTableQuantity,
  OrderTableSpanText,
  StyledImage,
} from "../../assets/style/Orderlist";
import { IoIosCloseCircle } from "react-icons/io";
import { formatDate, formattedNumber } from "../../util/const";
import { API_URL } from "../../api/axiosConfig";
import { useTranslation } from "react-i18next";
import { OrderCancel } from "../../api/orders/OrdersCancel";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderItem } from "../../store/action/OrderListAction";
import PwChangeModal from "../../modal/PwChangeModal";
import CancelModal from "../../modal/CancelModal";
import {
  CancelButton,
  EmailCheckButton,
} from "../../assets/style/login-register";
import { SizeBoxW5, SpanFont } from "../../assets/style/Common";
export const OrderTableBody = ({ order }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const currency = useSelector((state) => state.currency);

  /// 모달관리
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const [isLoading, setLoading] = useState(false);
  const handleDetailPage = (order) => {
    navigate("/OrderDetailPage", { state: { order } });
  };

  const getOrderStatusLabel = (status) => {
    switch (status) {
      case "pending":
        return "최초 주문 생성 상태";
      case "paid":
        return `${t("payment_completed")}`;
      case "processing":
        return `${t("in_production")}`;
      case "completed":
        return `${t("production_completed")}`;
      case "cancelled":
        return `${t("order_canceled")}`;
      default:
        return `${t("order_pending")}`;
    }
  };

  const handleCancel = async (id, setModalOpen, confirm = false) => {
    if (!confirm) {
      setModalOpen(true);
      return;
    }

    setLoading(true);
    try {
      const resp = await OrderCancel(id);

      if (resp.msg === "Success") {
        dispatch(updateOrderItem(id, "cancelled"));
      }

      if (resp.msg === "Fail") {
        console.log("주문취소 실패 사유", resp);
      }
    } catch (error) {
      console.log("주문취소의 알 수 없는 에러", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <tbody>
        <tr>
          <td className="product-thumbnail">
            <div style={{ marginLeft: `30px` }}>
              <OrderListTableBodyBox>
                <div>{getOrderStatusLabel(order.status)}</div>
              </OrderListTableBodyBox>
              <Link
                to={
                  process.env.PUBLIC_URL +
                  `/product/${order.items[0]?.product_id}`
                }
              >
                <ImageContainer>
                  <StyledImage
                    className="img-fluid"
                    src={`${API_URL}${order.items[0]?.product_image}`}
                    // src={order.items[0].product_image}
                    alt=""
                    // onError={(e) => {
                    //   e.target.src = { logo };
                    // }}
                  />
                </ImageContainer>
              </Link>
              {order.status === "paid" ? (
                <div className="button-box-custom">
                  <CancelButton
                    className="button-box"
                    disabled={isLoading}
                    onClick={() => handleCancel(order.id, setModalOpen)}
                  >
                    {t("order_cancel")}
                  </CancelButton>
                </div>
              ) : (
                <OrderCancelButton>
                  <div
                    style={{
                      width: `30px`,
                    }}
                  ></div>
                </OrderCancelButton>
              )}
            </div>
          </td>

          <OrderListTd>
            <OrderDayText>
              {formatDate(order.created_at)} {t("payment")}
              {/* {new Date(order.created_at).toLocaleDateString()} */}
            </OrderDayText>
            <Link
              to={
                process.env.PUBLIC_URL +
                `/product/${order.items[0]?.product_id}`
              }
            >
              {order.items && order.items.length > 0
                ? order.items[0].product_name
                : ""}
              {order.items && order.items.length >= 2 ? (
                <>
                  <span> {t("contain")} </span>
                  <OrderTableSpanText>
                    {t("total_k")} {order.items.length}
                    {t("gun")}
                  </OrderTableSpanText>
                </>
              ) : (
                <></>
              )}
            </Link>

            <div className="cart-item-variation">
              <span>
                {order.items && order.items.length > 0
                  ? order.shipping_name
                  : ""}
              </span>
              <span>
                {order.items && order.items.length > 0
                  ? order.shipping_phone
                  : ""}
              </span>
              <OrderDetailSpan onClick={() => handleDetailPage(order)}>
                {t("order_details")}
              </OrderDetailSpan>
            </div>
          </OrderListTd>

          <td
            className="product-price-cart"
            style={{
              minWidth: `150px`,
            }}
          >
            {/* <Fragment>
              <span className="amount">
                {order.items && order.items.length > 0
                  ? `${formattedNumber(order.items[0].price, "0,0")} 원 `
                  : ""}
                (
                {order.items && order.items.length > 0
                  ? formattedNumber(order.items[0].quantity, "0,0")
                  : ""}
                {t("items_count")})
              </span>
            </Fragment> */}
          </td>

          <OrderTableQuantity
            className="product-quantity"
            style={{
              minWidth: `90px`,
            }}
          >
            <div
              style={{
                fontWeight: `500`,
                display: `flex`,
              }}
            >
              <SpanFont>{currency.currencySymbol}</SpanFont>
              <SizeBoxW5 />
              {formattedNumber(order.total_amount, "0,0")}
            </div>
          </OrderTableQuantity>
          {/* {order.status === "paid" ? (
            <OrderCancelButton
              disabled={isLoading}
              onClick={() => handleCancel(order.id, setModalOpen)}
            >
              <IoIosCloseCircle size={30} />
            </OrderCancelButton>
          ) : (
            <OrderCancelButton>
              <div
                style={{
                  width: `30px`,
                }}
              ></div>
            </OrderCancelButton>
          )} */}
        </tr>
      </tbody>
      <CancelModal
        text={t("cancel_text")}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={() => handleCancel(order.id, setModalOpen, true)}
      />
    </>
  );
};
