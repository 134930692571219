import styled from "styled-components";

export const AddressModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const AddressModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  /* height: 700px; */
  width: 35rem;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow-x: hidden; /* 수평 스크롤 숨기기 */
  overflow-y: hidden; /* 수직 스크롤 가능하게 하되, 필요시만 나타나도록 */
`;

export const AddressCloseButton = styled.button`
  background-color: transparent;
  border: none;
`;

export const AddressTopDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const AddressImgBox = styled.div`
  height: 3rem;
`;

export const AddressLogoImg = styled.img`
  width: 100%;
  height: 100%;
`;
