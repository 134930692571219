import { useState } from "react";

const useLogin = () => {
  const [isLoginEmail, setLoginEmail] = useState("");
  const [isLoginPassword, setLoginPassword] = useState("");
  const [isLoginEmailError, setLoginEmailError] = useState(false);

  return {
    isLoginEmail,
    setLoginEmail,
    isLoginPassword,
    setLoginPassword,
    isLoginEmailError,
    setLoginEmailError,
  };
};

export default useLogin;
