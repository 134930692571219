const { createSlice } = require('@reduxjs/toolkit');

const metaSlice = createSlice({
    name: "meta",
    initialState: {

    },
    reducers: {
        setMeta(state, action) {
            const meta = action.payload;
                
            return state={
                meta:meta
            }
        }
    },
});

export const { setMeta } = metaSlice.actions;
export default metaSlice.reducer;
