import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ErrorPage from "./pages/other/Error";
import ForgotPw from "./pages/other/ForgotPw";
import ForgotChangePw from "./pages/other/ForgotChangePw";
import PayMentPage from "./pages/payment/PaymentPage";
import { element } from "prop-types";
import OrderList from "./pages/other/OrderList";
import PaymentCompleted from "./pages/other/PaymentCompleted";
import OrderDetailPage from "./pages/other/OrderDetail";
import { AuthGuard } from "./util/AuthGuard";
import InfoKoPage from "./pages/info/InfoKo";
import InfoEnPage from "./pages/info/InfoEn";
import ConditionKo from "./pages/info/ConditionKo";
import ConditionEn from "./pages/info/ConditionEn";
import { KakaoReduredctURL } from "./components/sns-login/KakaoLoginCallBack";

// home pages
const HomeCosmetics = lazy(() => import("./pages/home/HomeCosmetics"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));
const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridRightSidebar = lazy(() =>
  import("./pages/shop/ShopGridRightSidebar")
);
const ShopListStandard = lazy(() => import("./pages/shop/ShopListStandard"));
const ShopListFullWidth = lazy(() => import("./pages/shop/ShopListFullWidth"));
const ShopListTwoColumn = lazy(() => import("./pages/shop/ShopListTwoColumn"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
  import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
  import("./pages/shop-product/ProductFixedImage")
);

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const AuthCheck = lazy(() => import("./pages/other/AuthCheck"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <AuthGuard>
            <Routes>
              <Route
                path={process.env.PUBLIC_URL + "/"}
                element={<HomeCosmetics />}
              />
              {/* Shop pages */}
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-standard"}
                element={<ShopGridStandard />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-filter"}
                element={<ShopGridFilter />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-two-column"}
                element={<ShopGridTwoColumn />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}
                element={<ShopGridNoSidebar />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                element={<ShopGridFullWidth />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-right-sidebar"}
                element={<ShopGridRightSidebar />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-standard"}
                element={<ShopListStandard />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-full-width"}
                element={<ShopListFullWidth />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-two-column"}
                element={<ShopListTwoColumn />}
              />
              {/* Shop product pages */}
              <Route
                path={process.env.PUBLIC_URL + "/product/:id"}
                element={<Product />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-tab-left/:id"}
                element={<ProductTabLeft />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-tab-right/:id"}
                element={<ProductTabRight />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-sticky/:id"}
                element={<ProductSticky />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-slider/:id"}
                element={<ProductSlider />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
                element={<ProductFixedImage />}
              />
              {/* Blog pages */}
              <Route
                path={process.env.PUBLIC_URL + "/blog-standard"}
                element={<BlogStandard />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                element={<BlogNoSidebar />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-right-sidebar"}
                element={<BlogRightSidebar />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-details-standard"}
                element={<BlogDetailsStandard />}
              />
              {/* Other pages */}
              <Route
                path={process.env.PUBLIC_URL + "/about"}
                element={<About />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/contact"}
                element={<Contact />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/my-account"}
                element={<MyAccount />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/login-register"}
                element={<LoginRegister />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/cart"}
                element={<Cart />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/wishlist"}
                element={<Wishlist />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/compare"}
                element={<Compare />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/checkout"}
                element={<Checkout />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/authCheck"}
                element={<AuthCheck />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/error"}
                element={<ErrorPage />}
              />{" "}
              <Route
                path={process.env.PUBLIC_URL + "/forgotPw"}
                element={<ForgotPw />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/reset-password"}
                element={<ForgotChangePw />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/order-list"}
                element={<OrderList />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/payment"}
                element={<PayMentPage />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/OrderDetailPage"}
                element={<OrderDetailPage />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/PaymentCompleted"}
                element={<PaymentCompleted />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/infoKo"}
                element={<InfoKoPage />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/infoEn"}
                element={<InfoEnPage />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/ConditionKo"}
                element={<ConditionKo />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/ConditionEn"}
                element={<ConditionEn />}
              />
              <Route 
              path={process.env.PUBLIC_URL + "/kakaoLogin"} 
               element={<KakaoReduredctURL />} />
              
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AuthGuard>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

export default App;
