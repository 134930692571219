import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { deleteAllFromCart } from "../../store/slices/cart-slice";
import { ClipLoader } from "react-spinners";
import { fetchOrders } from "../../handler/orders/Orders";
import { OrderTableBody } from "../../components/order-list/TableBody";
import { SingUpDivBox } from "../../assets/style/login-register";
import {
  AllSizeBox,
  FlexBox,
  FlexCenterBox,
  ImgBox,
  SizeBoxH30,
  SizeBoxW5,
  SpanTextModalBoldOne,
  SpanTextModalBoldTwo,
} from "../../assets/style/Common";
import { ModalTextSpan } from "../../assets/style/Modal";
import logo from "../../assets/img/logo/logo.png";
import Paginator from "react-hooks-paginator";
import {
  setFirstOrderListPageData,
  updateOrderPage,
} from "../../store/action/OrderListAction";
import { useTranslation } from "react-i18next";
import { StyledText } from "../../assets/style/GlobalStyle";

const OrderList = () => {
  const allData = useSelector((state) => state);
  const firstOrder = useSelector((state) => state.firstOrderData.firstState);
  const getOrderData = useSelector(
    (state) => state.orderListData?.orderData?.items
  );
  const pageData = useSelector((state) => state.orderListPageData);
  // useEffect(() => {
  //   console.log("값좀볼까?", firstOrder);
  //   console.log("주문내역 잘 들어왔니?", getOrderData);
  //   console.log("모든정보", allData);
  // }, [firstOrder, getOrderData, allData]);
  const { t } = useTranslation();

  let cartTotalPrice = 0;
  const navigate = useNavigate();
  const [quantityCount] = useState(1);
  const dispatch = useDispatch();
  let { pathname } = useLocation();

  const [currentPage, setCurrentPage] = useState(pageData?.page);
  const [totalPage, setTotalPage] = useState(pageData?.total || 1);
  const [offset, setOffset] = useState(0);
  const pageLimit = pageData?.size;

  const currency = useSelector((state) => state.currency);
  const { cartItems } = useSelector((state) => state.cart);
  const [isLoading, setLoading] = useState(firstOrder);

  const pageChangeHandle = (e) => {
    dispatch(updateOrderPage(e));
    setCurrentPage(e);
  };

  /// 주문내역 페이지 호출시 최초 API호출
  useEffect(() => {
    if (firstOrder === true) {
      fetchOrders(setLoading, navigate, dispatch, currentPage, pageLimit);
      dispatch(setFirstOrderListPageData(false));
    }
  }, [firstOrder]);

  /// 페이지
  useEffect(() => {
    setCurrentPage(pageData.page);
    fetchOrders(setLoading, navigate, dispatch, pageData.page, pageLimit);
    setTotalPage(pageData?.total);
  }, [pageData.page]);

  return (
    <Fragment>
      <SEO
        titleTemplate="OrderList"
        description="OrderList page of flone react minimalist eCommerce template."
      />

      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: `${t("home")}`, path: process.env.PUBLIC_URL + "/" },
            {
              label: t("shop"),
              path: process.env.PUBLIC_URL + "/shop-grid-standard",
            },
            {
              label: `${t("order_list")}`,
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        <div className="cart-main-area pt-90 pb-100">
          <div className="container">
            {/* carItems는 이후 상품이 있는지 없는지로 변경예정 */}
            {isLoading ? (
              <>
                <FlexCenterBox>
                  <AllSizeBox width="300px">
                    <ImgBox src={logo} />
                  </AllSizeBox>
                </FlexCenterBox>
                <FlexCenterBox marginTop="40px">
                  <SpanTextModalBoldOne fontSize="20px">M</SpanTextModalBoldOne>
                  <SpanTextModalBoldTwo fontSize="20px">O</SpanTextModalBoldTwo>
                  <SpanTextModalBoldOne fontSize="20px">H</SpanTextModalBoldOne>
                  <SpanTextModalBoldTwo fontSize="20px">A</SpanTextModalBoldTwo>
                  <SizeBoxW5></SizeBoxW5>
                  <ModalTextSpan>{t("moha_using")}</ModalTextSpan>
                </FlexCenterBox>
                <FlexCenterBox marginTop="0px">
                  <ModalTextSpan>{t("moha_modal_msg")}</ModalTextSpan>
                </FlexCenterBox>
                <SizeBoxH30 />
                <SingUpDivBox>
                  <ClipLoader color="#ffaa77" size={80} />
                </SingUpDivBox>
              </>
            ) : getOrderData && getOrderData.length >= 1 ? (
              <Fragment>
                <FlexCenterBox className="row">
                  <div className="col-12">
                    <h3 className="cart-page-title">
                      <StyledText>{t("order_detail")}</StyledText>
                      {/* Your cart items */}
                    </h3>
                    {getOrderData.map((order, index) => (
                      <div
                        key={index}
                        className="table-content table-responsive cart-table-content"
                        style={{
                          overflowX: "auto",
                          width: "100%",
                        }}
                      >
                        <table style={{ borderBottom: "none" }}>
                          <OrderTableBody order={order} />
                        </table>
                      </div>
                    ))}
                  </div>
                </FlexCenterBox>
                <div className="pro-pagination-style text-center mt-30">
                  <Paginator
                    totalRecords={totalPage}
                    pageLimit={pageLimit}
                    pageNeighbours={2}
                    setOffset={setOffset}
                    currentPage={currentPage}
                    setCurrentPage={(e) => pageChangeHandle(e)}
                    pageContainerClass="mb-0 mt-0"
                    pagePrevText="«"
                    pageNextText="»"
                  />
                </div>
              </Fragment>
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="item-empty-area text-center">
                    <div className="item-empty-area__icon mb-30">
                      <FlexCenterBox>
                        <AllSizeBox width="400px" height="150px">
                          <ImgBox src={logo} alt="" />
                        </AllSizeBox>
                      </FlexCenterBox>
                      {/* <i className="pe-7s-cart"></i> */}
                    </div>
                    <SizeBoxH30 />
                    <div className="item-empty-area__text">
                      {t("not_order_list")} <br />{" "}
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                        {t("product_info")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default OrderList;
