import React from "react";
import {
  ModalBackdrop,
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalTextSpan,
} from "../assets/style/Modal";
import {
  FlexCenterBox,
  MarginTop20,
  SizeBoxH5,
  SizeBoxW5,
  SpanTextModalBoldOne,
  SpanTextModalBoldTwo,
} from "../assets/style/Common";
import logo from "../assets/img/logo/logo-1.png";
import { useTranslation } from "react-i18next";

const Modal = ({ isOpen, closeModal, title, children }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <ModalBackdrop isOpen={closeModal} onClick={closeModal}>
      <ModalContainer>
        <ModalHeader>
          <MarginTop20>
            <img src={logo} />
          </MarginTop20>
        </ModalHeader>
        <ModalBody>
          <SizeBoxH5 />
          <div
            style={{
              display: `flex`,
              justifyContent: `center`,
            }}
          >
            <ModalTextSpan>{t("success_sing_up")}</ModalTextSpan>
          </div>
          <FlexCenterBox marginTop="0px">
            <SpanTextModalBoldOne fontSize="20px">M</SpanTextModalBoldOne>
            <SpanTextModalBoldTwo fontSize="20px">O</SpanTextModalBoldTwo>
            <SpanTextModalBoldOne fontSize="20px">H</SpanTextModalBoldOne>
            <SpanTextModalBoldTwo fontSize="20px">A</SpanTextModalBoldTwo>
            <SizeBoxW5></SizeBoxW5>
            <ModalTextSpan>{t("email_check_text")}</ModalTextSpan>
          </FlexCenterBox>

          {children}
        </ModalBody>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default Modal;
