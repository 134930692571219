import api from "../axiosConfig";

export const OrderCancel = async (orderId) => {
  try {
    const resp = await api.post(`/api/v1/orders/${orderId}/cancle`);

    console.log("주문취소성공");

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("주문취소 실패", error);

    return {
      data: error,
      msg: "Fail",
    };
  }
};
