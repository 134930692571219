import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getDiscountPrice } from "../../helpers/product";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { AuthVerifyEmail } from "../../api/Authentication/AuthLogin";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";

import {
  ErrorMsgText,
  FlexCenterBox,
  SizeBoxH15,
  SizeBoxH30,
  SizeBoxH5,
  SpanTextBold,
  SpanTextBoldOne,
  SpanTextBoldTwo,
  SuccessMsgText,
  TextBox,
} from "../../assets/style/Common";
import {
  EmailCheckButton,
  ForgotEmailCheckButton,
  InputBox,
} from "../../assets/style/login-register";
import { useTranslation } from "react-i18next";
import {
  ForgotChangeCheckPasswordHandler,
  ForgotChangePasswordHandler,
  ForgotEmailHandler,
  handleForgotPw,
} from "../../handler/forgot-pw/ForgotPwHandler";
import { emailRegex } from "../../util/const";
import { AuthResetPassword } from "../../api/Authentication/AuthResetPassword";
import Modal from "../../modal/Modal";

const ForgotChangePw = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  let cartTotalPrice = 0;

  let { pathname } = useLocation();
  const currency = useSelector((state) => state.currency);
  const { cartItems } = useSelector((state) => state.cart);
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [isTokenOk, setTokenOk] = useState(null);

  const [isPassword, setPassword] = useState("");
  const [isCheckPassword, setCheckPassword] = useState("");
  const [isPwSameCheck, setPwSameCheck] = useState(false);
  const [isPwTrim, setPwTrim] = useState(false);

  const [isTimeOver, setTimeOver] = useState(false);
  const [isButtonLoading, setButtonLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPwRegex, setPwRegex] = useState(false);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => {
    setIsModalOpen(false); // 모달 닫기
    navigate(process.env.PUBLIC_URL + "/login-register"); // 특정 페이지로 이동
  };

  useEffect(() => {
    const verifyPassword = async () => {
      setLoading(true);
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get("token");
      if (token) {
        setTokenOk(token);
        setLoading(false);
      } else {
        console.warn("토큰이 존재하지 않습니다.");
        setLoading(false);
      }
    };

    verifyPassword();
  }, [location]);

  return (
    <Fragment>
      <SEO
        titleTemplate="Checkout"
        description="Checkout page of flone react minimalist eCommerce template."
      />
      <LayoutOne headerTop="visible">
        <div className="checkout-area pt-95 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="item-empty-area text-center">
                  <>
                    <div className="item-empty-area__text">
                      <>
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-7 col-md-12 ms-auto me-auto">
                              <div className="login-register-wrapper">
                                <div className="login-form-container">
                                  <div className="login-register-form">
                                    {isLoading === true ? (
                                      <FlexCenterBox>
                                        <ClipLoader color="#F68063" size={50} />
                                      </FlexCenterBox>
                                    ) : (
                                      <form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                        }}
                                      >
                                        <TextBox>{t("change_pw")}</TextBox>
                                        <FlexCenterBox>
                                          <SpanTextBoldOne>M</SpanTextBoldOne>
                                          <SpanTextBoldTwo>O</SpanTextBoldTwo>
                                          <SpanTextBoldOne>H</SpanTextBoldOne>
                                          <SpanTextBoldTwo>A</SpanTextBoldTwo>
                                          <span>{t("ing_pw")}</span>
                                          <br />
                                        </FlexCenterBox>

                                        <SizeBoxH30></SizeBoxH30>
                                        <InputBox
                                          type="password"
                                          placeholder="비밀번호"
                                          value={isPassword}
                                          onChange={(e) => {
                                            ForgotChangePasswordHandler(
                                              e,
                                              setPassword
                                            );
                                          }}
                                          hasError={isPwTrim || isPwSameCheck}
                                          marginBottom="0px"
                                        />
                                        <SizeBoxH5 />
                                        <InputBox
                                          type="password"
                                          placeholder="비밀번호 확인"
                                          value={isCheckPassword}
                                          onChange={(e) => {
                                            ForgotChangeCheckPasswordHandler(
                                              e,
                                              setCheckPassword
                                            );
                                          }}
                                          hasError={isPwTrim || isPwSameCheck}
                                          marginBottom="0px"
                                        />
                                        <SizeBoxH5 />
                                        {isPwTrim ? (
                                          <ErrorMsgText>
                                            {t("pw_error_msg")}
                                          </ErrorMsgText>
                                        ) : (
                                          isPwSameCheck && (
                                            <ErrorMsgText>
                                              {t("not_same_pw")}
                                            </ErrorMsgText>
                                          )
                                        )}
                                        {isTimeOver && (
                                          <ErrorMsgText>
                                            {t("time_out_pw")}
                                          </ErrorMsgText>
                                        )}
                                        <SizeBoxH15 />

                                        {isButtonLoading ? (
                                          <FlexCenterBox>
                                            <ClipLoader
                                              color="#F68063"
                                              size={50}
                                            />
                                          </FlexCenterBox>
                                        ) : isTimeOver ? (
                                          <div>
                                            <ForgotEmailCheckButton
                                              //   disabled={}
                                              type="submit"
                                              onClick={() => {
                                                navigate(
                                                  process.env.PUBLIC_URL +
                                                    "/forgotPw"
                                                ); // 해당 경로로 이동
                                              }}
                                            >
                                              <span>{t("go_pw_page")}</span>
                                            </ForgotEmailCheckButton>
                                          </div>
                                        ) : (
                                          <div>
                                            <ForgotEmailCheckButton
                                              //   disabled={}
                                              type="submit"
                                              onClick={() => {
                                                handleForgotPw(
                                                  isPassword,
                                                  isCheckPassword,
                                                  isTokenOk,
                                                  setPwSameCheck,
                                                  setPwTrim,
                                                  setTimeOver,
                                                  setButtonLoading,
                                                  setIsModalOpen,
                                                  setPwRegex
                                                );
                                              }}
                                            >
                                              <span>{t("re_pw_change")}</span>
                                            </ForgotEmailCheckButton>
                                          </div>
                                        )}
                                      </form>
                                    )}
                                  </div>
                                  <div>
                                    {/* <OpenModalButton onClick={openModal}>
                                      모달 열기
                                    </OpenModalButton> */}
                                    <Modal
                                      isOpen={isModalOpen}
                                      closeModal={closeModal}
                                      title={t("change_pw")}
                                    >
                                      {/* <p>여기에 모달 내용이 들어갑니다.</p> */}
                                      <div
                                        style={{
                                          marginTop: `20px`,
                                          display: `flex`,
                                          justifyContent: `center`,
                                        }}
                                      >
                                        <ForgotEmailCheckButton
                                          onClick={closeModal}
                                        >
                                          MOHA {t("login_register_login")}
                                        </ForgotEmailCheckButton>
                                      </div>
                                    </Modal>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default ForgotChangePw;
