import { IoIosClose } from "react-icons/io";
import logo from "../assets/img/logo/logo-1.png";
import {
  AddressCloseButton,
  AddressImgBox,
  AddressLogoImg,
  AddressModalBackdrop,
  AddressModalContainer,
  AddressTopDiv,
} from "../assets/style/AddressModal";
import {
  FlexCenterBox,
  SizeBoxH30,
  SizeBoxH5,
  SizeBoxW5,
  SpanTextModalBoldOne,
  SpanTextModalBoldTwo,
} from "../assets/style/Common";
import { ModalTextSpan } from "../assets/style/Modal";
import Success from "../assets/img/analyze_complate.png";
import {
  EmailCheckButton,
  ModalCheckButton,
  ModalCheckButton2,
} from "../assets/style/login-register";
import { useTranslation } from "react-i18next";
import cancel from "../assets/img/circle.png";
const CancelModal = ({ isOpen, onClose, text, onConfirm }) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <AddressModalBackdrop onClick={handleBackdropClick}>
      <AddressModalContainer>
        <AddressTopDiv>
          <AddressImgBox>
            <AddressLogoImg src={logo} alt="로고" />
          </AddressImgBox>
          <AddressCloseButton onClick={onClose}>
            <IoIosClose size={35} />
          </AddressCloseButton>
        </AddressTopDiv>
        <SizeBoxH5 />
        <FlexCenterBox>
          <img src={cancel} alt="" />
        </FlexCenterBox>
        <SizeBoxH30 />
        <FlexCenterBox marginTop="0px">
          <SpanTextModalBoldOne fontSize="20px">M</SpanTextModalBoldOne>
          <SpanTextModalBoldTwo fontSize="20px">O</SpanTextModalBoldTwo>
          <SpanTextModalBoldOne fontSize="20px">H</SpanTextModalBoldOne>
          <SpanTextModalBoldTwo fontSize="20px">A</SpanTextModalBoldTwo>
          <SizeBoxW5></SizeBoxW5>
          <ModalTextSpan>{text}</ModalTextSpan>
        </FlexCenterBox>
        <SizeBoxH30 />
        <FlexCenterBox className="button-box-custom">
          <ModalCheckButton
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            {t("yes_text")}
          </ModalCheckButton>
          <SizeBoxW5 />
          <ModalCheckButton2 onClick={handleBackdropClick}>
            {t("no_text")}
          </ModalCheckButton2>{" "}
        </FlexCenterBox>
      </AddressModalContainer>
    </AddressModalBackdrop>
  );
};

export default CancelModal;
