const { createSlice } = require('@reduxjs/toolkit');

const orderSlice = createSlice({
    name: "order",
    initialState: {

    },
    reducers: {
        setOrder(state, action) {
            const order = action.payload;
                
            return state= [order]
            
        },
        deleteOrder(state){
            state = []
        }
    },
});

export const { setOrder,deleteOrder} = orderSlice.actions;
export default orderSlice.reducer;
