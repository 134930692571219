import styled from "styled-components";

export const SizeBoxH5 = styled.div`
  height: 5px;
`;

export const SizeBoxW5 = styled.div`
  width: 5px;
`;
export const FlexBox = styled.div`
  display: flex;
`;
export const SizeBoxH15 = styled.div`
  height: 15px;
`;

export const MarginTop20 = styled.div`
  margin-top: 20px;
`;

export const MarginTop10 = styled.div`
  margin-top: 10px;
`;

export const TextColorRed = styled.div`
  color: red;
`;

export const SizeBoxH30 = styled.div`
  height: 30px;
`;

export const FlexCenterBox = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "marginTop", // 'marginTop'은 DOM에 전달되지 않도록 설정
})`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.marginTop || "0px"};
`;

export const FlexStartBox = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "marginTop", // 'marginTop'은 DOM에 전달되지 않도록 설정
})`
  display: flex;
  justify-content: start;
  margin-top: ${(props) => props.marginTop || "0px"};
`;

export const FlexEndBox = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "marginTop", // 'marginTop'은 DOM에 전달되지 않도록 설정
})`
  display: flex;
  justify-content: end;
  margin-top: ${(props) => props.marginTop || "0px"};
`;

export const FlexCenterHeightBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.marginTop || "0px"};
  flex-direction: column;
`;

export const SpanTextBoldOne = styled.span`
  font-weight: 700;
  color: #ffaa77;
`;

export const SpanTextModalBoldOne = styled.span`
  font-weight: 700;
  color: #ffaa77;
  font-size: ${(props) => props.fontSize || "16px"}; // 기본 값 16px
`;

export const SpanTextModalBoldTwo = styled.span`
  font-weight: 700;
  color: #f68063;
  font-size: ${(props) => props.fontSize || "16px"}; // 기본 값 16px
`;

export const SpanModalTitleText = styled.span`
  font-family: 700;
  color: #333;
  font-size: ${(props) => props.fontSize || "16px"}; // 기본 값 16px
`;

export const TextBox = styled.div`
  color: #f68063;
  display: flex;
  justify-content: center;
  font-size: 35px;
  margin-bottom: 40px;
  font-weight: 300;
`;

export const SpanTextBoldTwo = styled.span`
  font-weight: 700;
  color: #f68063;
`;

export const ErrorMsgText = styled.div`
  font-size: 12px;
  color: red;
`;

export const SuccessMsgText = styled.div`
  font-size: 12px;
  color: skyblue;
`;

export const OrdersButton = styled.button`
  background-color: red;
  font-weight: 500;
  line-height: 1;
  z-index: 9;
  display: block;
  width: 100%;
  padding: 18px 20px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  border: none;
  border-radius: 50px;
  background: none;
  background-color: ${({ theme }) => theme.color}; /* $theme-color를 대체 */

  &:hover {
    background-color: #f68063;
  }
`;

export const SidePaddingDiv = styled.div.withConfig({
  shouldForwardProp: (prop) => !["paddingRight", "paddingLeft"].includes(prop),
})`
  padding-right: ${(props) => props.paddingRight || "0px"};
  padding-left: ${(props) => props.paddingLeft || "0px"};
`;
export const ModalCloseIconBox = styled.div`
  display: flex;
  justify-content: end;
  cursor: pointer;
`;

export const ImgBox = styled.img`
  height: 100%;
  width: 100%;
`;

export const ImgBox2 = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const AllSizeBox = styled.div`
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
`;

export const ImgProfileBox = styled.img`
  height: 100%;
  width: 100%;
  /* object-fit: cover; */
`;

export const HeaderLogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const HeaderLogoLinkBox = styled.div`
  height: 26px;
  width: 103px;
`;

export const HeaderLogoImg = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const SpanFont = styled.div`
  font-family: Arial, Helvetica, sans-serif;
`;

export const FlexDiv = styled.div`
  display: flex;
`;

export const SpanFlex = styled.span`
  display: flex;
`;
