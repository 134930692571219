import { RESET_DATA } from "../action/ResetAction";
import {
  ADDRESS_CHANGE,
  INCREMENT_COUNTER,
  SET_USER_DATA,
  UPDATE_USER_CONTACT_INFO,
  UPDATE_USER_PROFILE,
} from "../action/UserAction";

const initialState = {
  id: null,
  email: null,
  name: null,
  phone: null,
  address_1: null,
  address_2: null,
  profileImage: null,
  isActive: null,
  createdAt: null,
  updatedAt: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        id: action.payload.id,
        email: action.payload.email,
        name: action.payload.name,
        phone: action.payload.phone,
        address_1: action.payload.address_1,
        address_2: action.payload.address_2,
        profileImage: action.payload.profile_image,
        isActive: action.payload.is_active,
        createdAt: action.payload.created_at,
        updatedAt: action.payload.updated_at,
      };

    case UPDATE_USER_CONTACT_INFO:
      return {
        ...state,
        name: action.payload.name,
        phone: action.payload.phone,
        address_1: action.payload.address_1,
        address_2: action.payload.address_2,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        profileImage: action.payload.profile_image,
      };

    case ADDRESS_CHANGE:
      return {
        ...state,
        address_1: action.payload.address_1,
        address_2: action.payload.address_2,
      };
    case RESET_DATA:
      return initialState;
    default:
      return state;
  }
};

const initialStateCount = {
  counter: 0,
};

export const reRandDataReducer = (state = initialStateCount, action) => {
  switch (action.type) {
    case INCREMENT_COUNTER:
      return {
        ...state,
        counter: state.counter + 1,
      };
    case RESET_DATA:
      return initialStateCount;
    default:
      return state;
  }
};
