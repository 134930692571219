import { AUTH_ERROR, RESET_AUTH_ERROR } from "../action/AuthGuardAction";
import { RESET_DATA } from "../action/ResetAction";

const initialState = {
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_AUTH_ERROR:
      return {
        ...state,
        error: null,
      };

    case RESET_DATA:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
