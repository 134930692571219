import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { productAPI } from '../../api/axiosConfig';
import { GetProduct } from '../../api/products/ProductApi';

// export const fetchProducts = createAsyncThunk(
//     'product/fetchProducts',
//     async (params, { rejectWithValue }) => {
//       try {
//         const response = await GetProduct(params);

//         return response;
//       } catch (error) {
//         return rejectWithValue(
//           error.response?.data?.message || '상품을 불러오는데 실패했습니다.'
//         );
//       }
//     }
//   );

const initialState = {
  products: [],
  loading: false,
  error: null,
  isFetch:false
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
  },
});

export const { setProducts } = productSlice.actions;
export default productSlice.reducer;