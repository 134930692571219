import styled, { createGlobalStyle } from "styled-components";
import BMJUAOTF from "../fonts/BMJUA_otf.otf";
import BMJUATTF from "../fonts/BMJUA_ttf.ttf";

export const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'BMJUA';
    src: url(${BMJUAOTF}) format('opentype'),
    url(${BMJUATTF}) format('truetype');

    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'BMJUA', sans-serif;
}
`;

export const StyledText = styled.div`
  font-family: "BMJUA", sans-serif !important;
`;

export const StyledTextH5 = styled.h5`
  font-family: "BMJUA", sans-serif !important;
`;
export const StyledTextH4 = styled.h4`
  font-family: "BMJUA", sans-serif !important;
`;
