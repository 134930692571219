import Swal from "sweetalert2";
import { BuyProduct } from "../../api/orders/Orders"

export const handleProductBuy= async (cartItems,shippingData,setIsDisabled,startPayment,isRest)=>{
    console.log(cartItems)
    setIsDisabled(true)
    if (!shippingData.shipping_name){
        Swal.fire({
            title: `이름을 입력해주세요.`,
            icon: `error`,
          });
          return;
    }else if (!shippingData.shipping_address){
        Swal.fire({
            title: `주소를 입력해주세요.`,
            icon: `error`,
          });
          return;
    // }else if (!isRest){
    //     Swal.fire({
    //         title: `남은 주소를 입력해주세요`,
    //         icon: `error`,
    //       });
    //       return;
    }else if (!shippingData.payment_method){
        Swal.fire({
            title: `결제방법을 선택해주세요`,
            icon: `error`,
          });
          return;
    }else if (!shippingData.shipping_phone){
        Swal.fire({
            title: `전화번호를 입력해주세요`,
            icon: `error`,
          });
          return;
    }
    const items = cartItems.map((item)=>{
        let options=[]
        if (item.options){
            for (const op of item.options) {
                if (op.type==="color"||op.type==="radio"){
                    const values =op.values.split("_")
                    const selectOp= {
                        option_group_id:op.id,
                        option_id:values[1],
                        value:values[2]
                    }
                    options.push(selectOp)
                }else if (op.type==="checkbox"){
                    for(const o of op.values){
                        const selectOp={
                            option_group_id:op.id,
                            option_id:o.id,
                            value:o.value
                        }
                        options.push(selectOp)
                    }
                }else if (op.type==="text" || op.type==="textarea"){
                    const selectOp={
                        option_group_id:op.id,
                        option_id:0,
                        value:op.values
                    }
                    options.push(selectOp)

                }else if (op.type==="file"){
                    for (const file of op.file){
                        const selectOp={
                            option_group_id:op.id,
                            option_id:0,
                            value:file
                        }
                        options.push(selectOp)
                    }
                    
                }else{
                    const values =op.values.split("_")
                    const selectOp= {
                        option_group_id:op.id,
                        option_id:values[0],
                        value:values[1]
                    }
                    options.push(selectOp)
                }
            }

        }

        return {
            product_id : item.id,
            quantity : item.quantity,
            selected_options:options
        }
    })
    shippingData.items = items
    // return shippingData
    startPayment(shippingData)
    // console.log(url)
    // window.location.href=url
}