import { RESET_DATA } from "../action/ResetAction";
import { SET_TOKEN } from "../action/TokenAction";

const initialState = {
  isTokenSet: false,
};

export const setTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        isTokenSet: action.payload,
      };
    case RESET_DATA:
      return initialState;
    default:
      return state;
  }
};
