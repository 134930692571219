import styled from "styled-components";

export const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  overflow: hidden;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const OrderDetailButton = styled.button`
  width: 100%;
  height: 25px;
  background-color: #ffaa77;
  border: medium none;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  &:hover {
    background-color: #f68063;
    color: #fff;
  }
  &:disabled {
    background-color: #f2f2f2;
    color: #333;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const OrderStateBox = styled.div`
  text-align: start;
  font-weight: 700;
  margin: 0 0 5px 3px;
`;

export const OrderListTd = styled.td`
  width: 870px;
  text-align: left !important;
  padding-left: 30px !important;
  a {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    text-align: left;
    &:hover {
      color: #ffaa77;
    }
  }
`;

export const OrderButtonBox = styled.div`
  border-bottom: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  padding: 0 30px 10px 30px;
  margin-bottom: 5px;
`;

export const OrderDetailSpan = styled.span`
  cursor: pointer;
  color: #ffaa77;

  &:hover {
    color: #f68063;
  }
`;

export const OrderDayText = styled.div`
  color: #aaaaac;
  margin-right: 5px;
`;

export const OrderDetailOptionBox = styled.div`
  display: flex;
  height: 18px;
  justify-content: center;
  align-items: center;
  border: 1px solid #aaaaac;
  padding: 0 5px 0 5px;
  font-size: 12px;
  color: #aaaaac;
  /* width: 500px; */
  min-width: 50px;
  border-radius: 8px;
  margin-right: 5px;
`;

export const OrderFlexCenterBox = styled.div`
  display: flex;
  align-items: center;
`;

export const OrderListTableBodyBox = styled.div`
  text-align: start;
  font-weight: 700;
  margin: 0 0 5px 3px;
`;

export const OrderTableSpanText = styled.span`
  font-weight: 700;
  color: #f68063;
`;

export const OrderTableQuantity = styled.td`
  /* padding-right: 30px; */
  width: 280px;
`;

export const OrderPayBox = styled.hr`
  border: 1px solid #eaeaea;
  opacity: 0.5;
  margin-left: 10px;
`;

export const OrderPayBox2 = styled.hr`
  border: 1px solid #eaeaea;
  opacity: 0.5;
`;

export const OrderFlexSB = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OrderMemoBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OrderNoMemoBox = styled.div`
  margin-bottom: 5px;
  color: #aaaaac;
`;

export const OrderCancelButton = styled.button`
  border: none;
  background-color: transparent;
  margin-right: 10px;
  margin-top: 10px;
  color: #ffaa77;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #f68063;
  }
`;

export const OrderDetailBox = styled.div`
  display: flex;
`;

export const OrderDetailTextBox = styled.div`
  margin-left: 5px;
  font-weight: 700;
  font-size: 16px;
`;

export const OrderGroupNameBox = styled.div`
  font-weight: 700;
  font-size: 12px;
`;

export const OrderGroupValueBox = styled.div`
  font-weight: 500;
  font-size: 11px;
`;
