import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import productReducer from "./slices/product-slice";
import currencyReducer from "./slices/currency-slice";
import cartReducer from "./slices/cart-slice";
import compareReducer from "./slices/compare-slice";
import wishlistReducer from "./slices/wishlist-slice";
import metaReducer from "./slices/meta-slice";
import orderReducer from "./slices/order-slice";
// import { userReducer } from "./reducer/UserReducer";
import { reRandDataReducer, userReducer } from "./reducer/UserReducer";
import authReducer from "./reducer/AuthGuardReducer";
import orderListReducer, {
  firstOrderListReducer,
  setOrderDataReducer,
} from "./reducer/OrderListReducer";
import { setTokenReducer } from "./reducer/TokenReducer";

const persistConfig = {
  key: "flone",
  version: 1.1,
  storage,
  blacklist: ["product"],
};

export const rootReducer = combineReducers({
    product: productReducer,
    currency: currencyReducer,
    cart: cartReducer,
    compare: compareReducer,
    wishlist: wishlistReducer,
    meta: metaReducer,
    userData: userReducer,
    orderData: orderReducer,
    authState: authReducer,
    orderListPageData: orderListReducer,
    reRandData: reRandDataReducer,
    firstOrderData: firstOrderListReducer,
    orderListData: setOrderDataReducer,
    tokenState: setTokenReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  // reducer: {
  // persist: persistedReducer,
  // product: productReducer,
  // },
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
