import styled from "styled-components";

const xsLayout = "only screen and (max-width: 767px)";
const mdLayout = "only screen and (min-width: 768px) and (max-width: 991px)";
const lgLayout = "only screen and (min-width: 992px) and (max-width: 1199px)";
const xlLayout = "only screen and (min-width: 1200px) and (max-width: 1365px)";

export const HeaderProfileBox = styled.div`
  height: ${(props) => props.height || "27px"};
  width: ${(props) => props.width || "27px"};
  border-radius: 100%;
  border: 1px solid #ebebeb;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const LoginDropdown = styled.div`
  position: absolute;
  z-index: 220;
  top: 190%;
  right: 0;

  border-radius: 8px;

  visibility: hidden;

  width: 280px;
  height: 310px;

  padding: 0px;

  transition: all 0.5s ease 0s;

  transform: rotateX(90deg);
  transform-origin: center top 0;
  text-align: left;

  opacity: 0;
  background: #fff none repeat scroll 0 0;

  box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);

  &.active {
    visibility: visible;

    transform: rotateX(0deg);

    opacity: 1;
  }

  @media ${xsLayout} {
    top: 100%;

    margin-top: 14px;
  }

  @media ${mdLayout} {
    top: 100%;

    margin-top: 14px;
  }

  @media ${lgLayout} {
    top: 100%;

    margin-top: 14px;
  }

  @media ${xlLayout} {
    top: 100%;

    margin-top: 14px;
  }
`;

export const LoginDropdown2 = styled.div`
  position: absolute;
  z-index: 220;
  top: 170%;
  right: 0;

  border-radius: 8px;

  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  /* height: 150px; */

  padding: 0px;

  transition: all 0.5s ease 0s;

  transform: rotateX(90deg);
  transform-origin: center top 0;
  text-align: left;

  opacity: 0;
  background: #fff none repeat scroll 0 0;

  box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);

  &.active {
    visibility: visible;

    transform: rotateX(0deg);

    opacity: 1;
  }

  @media ${xsLayout} {
    top: 100%;

    margin-top: 14px;
  }

  @media ${mdLayout} {
    top: 100%;

    margin-top: 14px;
  }

  @media ${lgLayout} {
    top: 100%;

    margin-top: 14px;
  }

  @media ${xlLayout} {
    top: 100%;

    margin-top: 14px;
  }
`;

export const UserIconDataBoxButtonRight = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #ebebeb;
  border-top-left-radius: 500px;
  border-end-start-radius: 500px;
  padding-left: 10px;
  width: 125px;
  height: 40px;
  border: none;
  cursor: pointer;
  /* color: #fff; */
  font-weight: 400;
  font-size: 13px;

  transition: background-color 0.35s ease, color 0.35s ease;

  &:hover {
    background-color: #ffaa77;
    color: #fff;
  }
`;

export const HeaderPageGroupDiv = styled.div`
  cursor: pointer;
  transition: color 0.35s ease;
  &:hover {
    color: #ffaa77;
  }
`;

export const UserIconDataBoxButtonLeft = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #ebebeb;
  border-top-right-radius: 500px;
  border-end-end-radius: 500px;
  padding-left: 10px;
  width: 125px;
  height: 40px;
  border: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;

  transition: background-color 0.35s ease, color 0.35s ease;

  &:hover {
    background-color: #ffaa77;
    color: #fff;
  }
`;
