export const ORDER_LIST_PAGE_DATA = "ORDER_LIST_PAGE_DATA";
export const FIRST_ORDER_LIST_DATA = "FIRST_ORDER_LIST_DATA";
export const ORDER_LIST_SET_DATA = "ORDER_LIST_SET_DATA";
export const ORDER_UPDATE_PAGE = "ORDER_UPDATE_PAGE";
export const UPDATE_ORDER_ITEM = "UPDATE_ORDER_ITEM";

export const setOrderListPageData = (meta) => {
  return {
    type: ORDER_LIST_PAGE_DATA,
    payload: meta,
  };
};

export const setFirstOrderListPageData = (first) => {
  return {
    type: FIRST_ORDER_LIST_DATA,
    payload: first,
  };
};

export const setOrderListData = (orderData) => {
  return {
    type: ORDER_LIST_SET_DATA,
    payload: orderData,
  };
};

export const updateOrderPage = (page) => {
  return {
    type: ORDER_UPDATE_PAGE,
    payload: page,
  };
};

export const updateOrderItem = (id, status) => ({
  type: UPDATE_ORDER_ITEM,
  payload: { id, status },
});
