// 비밀번호 찾기 이메일 전송

import api from "../axiosConfig";

export const AuthForgotPassword = async (email) => {
  try {
    const data = {
      email_address: email,
    };

    const resp = await api.post("/api/v1/auth/forgot-password", data);

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("비밀번호 변경 이메일 전송 실패", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
