import { AuthLogin, AuthMe } from "../../api/Authentication/AuthLogin";
import { setTokenAction } from "../../store/action/TokenAction";
import { setUserData } from "../../store/action/UserAction";
import { setCookie } from "../../util/Cookie";
import { TokenName } from "../../util/const";

// 로그인 이메일
export const handleLoginEmail = (e, setLoginEmail) => {
  setLoginEmail(e.target.value);
};
// 로그인 비밀번호
export const handleLoginPW = (e, setLoginPassword) => {
  setLoginPassword(e.target.value);
};

// 로그인
export const handleLogin = async (
  email,
  pw,
  navigate,
  setLoginEmailError,
  dispatch
) => {
  try {
    const resp = await AuthLogin(email, pw);

    if (resp.msg === "Success") {
      setLoginEmailError(false);
      setCookie(TokenName, resp.data.data.access_token, {
        path: "/",
        maxAge: 3600,
      });
      dispatch(setTokenAction(true));

      // setTimeout(() => {
      //   dispatch(setTokenAction(false));
      // }, 3600000);
    }

    if (resp.msg === "Fail") {
      console.log("Fail", resp);
      setLoginEmailError(true);
    }

    const userResp = await AuthMe(resp.data.data.access_token);
    if (userResp.msg === "Success") {
      dispatch(setUserData(userResp.data.data));
    }

    if (userResp.msg === "Fail") {
      console.log("Fail", userResp);
      setLoginEmailError(true);
    }
  } catch (error) {
    console.log("로그인 알 수 없는 Error", error);
    return {
      data: error,
      msg: "Fail",
    };
  }

  return;
};

// 로그인
export const handleLogin2 = async (
  email,
  pw,
  navigate,
  setLoginEmailError,
  dispatch
) => {
  try {
    setLoginEmailError(true);
    const resp = await AuthLogin(email, pw);

    if (resp.msg === "Success") {
      setLoginEmailError(false);
      setCookie(TokenName, resp.data.data.access_token, {
        path: "/",
        maxAge: 3600,
      });
      dispatch(setTokenAction(true));
    }

    if (resp.msg === "Fail") {
      console.log("Fail", resp);
      setLoginEmailError(true);
    }

    const userResp = await AuthMe(resp.data.data.access_token);
    if (userResp.msg === "Success") {
      dispatch(setUserData(userResp.data.data));
      navigate("/");
    }

    if (userResp.msg === "Fail") {
      console.log("Fail", userResp);
      setLoginEmailError(true);
    }
  } catch (error) {
    console.log("로그인 알 수 없는 Error", error);
    return {
      data: error,
      msg: "Fail",
    };
  } finally {
  }

  return;
};
