import api from "../axiosConfig";

export const BuyProduct = async (data)=>{
    try {
        // const data = {
        //   email: email,
        // };
    
        const resp = await api.post("/api/v1/orders",data);
    
        return resp.data
      
      } catch (error) {
        
        console.error("Error buying products:", error);

        return error;
      }
}