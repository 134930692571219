import {
  FIRST_ORDER_LIST_DATA,
  ORDER_LIST_PAGE_DATA,
  ORDER_LIST_SET_DATA,
  ORDER_UPDATE_PAGE,
  UPDATE_ORDER_ITEM,
} from "../action/OrderListAction";
import { RESET_DATA } from "../action/ResetAction";

const initialState = {
  page: 1,
  size: 10,
  total: null,
  totalPage: null,
};

const orderListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_LIST_PAGE_DATA:
      return {
        ...state,
        page: action.payload.page,
        size: action.payload.size,
        total: action.payload.total,
        totalPage: action.payload.total_pages,
      };
    case ORDER_UPDATE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case RESET_DATA:
      return initialState;
    default:
      return state;
  }
};

export default orderListReducer;

const orderInitialState = {
  orderData: null,
};

export const setOrderDataReducer = (state = orderInitialState, action) => {
  switch (action.type) {
    case ORDER_LIST_SET_DATA:
      return {
        orderData: action.payload,
      };
    case UPDATE_ORDER_ITEM: {
      const { id, status } = action.payload;

      return {
        ...state,
        orderData: {
          ...state.orderData,
          items: state.orderData.items.map((item) =>
            item.id === id ? { ...item, status } : item
          ),
        },
      };
    }
    case RESET_DATA:
      return orderInitialState;
    default:
      return state;
  }
};

const firstInitialState = {
  firstState: true,
};

export const firstOrderListReducer = (state = firstInitialState, action) => {
  switch (action.type) {
    case FIRST_ORDER_LIST_DATA:
      return {
        firstState: action.payload,
      };
    case RESET_DATA:
      return firstInitialState;
    default:
      return state;
  }
};
