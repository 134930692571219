import styled from "styled-components";

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.isOpen ? "block" : "none")};
  z-index: 999;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* maxWidth="700px" */
  max-width: ${(props) => props.maxWidth || "500px"}; /* 기본값: 500px */
  width: 100%;
  z-index: 1000;
`;
export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalHeader2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ModalBody = styled.div`
  margin-top: 20px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
`;

export const ModalTextSpan = styled.span`
  font-size: 20px;
  color: #333;
`;

export const CustomBr = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid #eeeef4;
  border-radius: 16px;
  margin-top: 15px;
  margin-bottom: 15px;
`;
