import { useEffect, useState } from "react";
import "../payment/PaymentPage.css";
import cardImg from "../../assets/img/payment/card.png";
import kakaoImg from "../../assets/img/payment/kakao.png";
import naverImg from "../../assets/img/payment/naver.png";
import samsungImg from "../../assets/img/payment/samsung.png";
// import ModalTwo from "./ModalTwo";
import { IoIosArrowDown } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { handleProductBuy } from "../../handler/orders/OrderHandle";
import { useSelector } from "react-redux";

// import { ModalOne } from "./ModalOne";

const PayMentPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const fullAddress = queryParams.get("fullAddress");
  const zoneCode = queryParams.get("zoneCode");
  const name = queryParams.get("name");
  const phoneNumber = queryParams.get("phoneNumber");
  const email = queryParams.get("email");
  const info = queryParams.get("info");
  const total = queryParams.get("cartTotalPrice");
  const isCart = queryParams.get("isCart") === 'true';


  const { cartItems } = useSelector((state) => state.cart);
  const orderItem = useSelector((state) => state.orderData)

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const [payType, setPayType] = useState("creditcard");
  const [allAgree, setAllAgree] = useState(false);
  const [coffeePolicy, setCoffeePolicy] = useState(false);
  const [paymentPolicy, setPaymentPolicy] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentItem,setCurrentItem] = useState("")
  const [showModal2, setShowModal2] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false)

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal2 = () => {
    setShowModal2(true);
  };

  const handleCloseModal2 = () => {
    setShowModal2(false);
  };

  const handlePaymentChange = (e) => {
    setPayType(e.target.value);
  };

  const handleAllAgreeChange = (e) => {
    const isChecked = e.target.checked;
    setAllAgree(isChecked);
    setCoffeePolicy(isChecked);
    setPaymentPolicy(isChecked);
  };

  useEffect(()=>{
    if(isCart){
      setCurrentItem(cartItems)
      console.log("EEEEE",currentItem)
    }else{
      setCurrentItem(orderItem)
    }
  },[])

  return (
    <div>
      <div className="banerBody body">
        <div style={{ display: "none" }}>
          <span id="memo"></span>

          <span id="name"></span>
        </div>
        <div className="container-fluid border" style={{ maxWidth: `530px` }}>
          <div className="my-4">
            <h2 className="text-center">
              <strong>결제하기</strong>
            </h2>
          </div>

          <div className="mb-3">
            <h3>
              <strong>최종 결제 금액</strong>
            </h3>
          </div>
          <div className="mb-4 p-2">
            <div className="border p-2 d-flex justify-content-between">
              <div>
                <span id="money">{total}</span>
              </div>
              <div>원</div>
            </div>
          </div>
          <div className="mb-3">
            <h3>
              <strong>결제 수단 선택</strong>
            </h3>
          </div>
          <div className="mb-4 d-flex justify-content-center">
            <label htmlFor="radios1">
              <div
                className={`text-center paymentBox ${
                  payType === "creditcard" ? `selected-payment` : ""
                }`}
              >
                <input
                  className="payRadioPayment"
                  type="radio"
                  name="payment"
                  id="radios1"
                  value="creditcard"
                  onChange={handlePaymentChange}
                />
                <div className="payLogoDiv">
                  <img className="payLogos" src={cardImg} alt="" />
                </div>
                <p className="payTextColor">신용카드</p>
              </div>
            </label>
            <label htmlFor="radios2">
              <div
                className={`text-center paymentBox ${
                  payType === "kakaopay" ? `selected-payment` : ""
                }`}
              >
                <input
                  className="payRadioPayment"
                  type="radio"
                  name="payment"
                  id="radios2"
                  value="kakaopay"
                  onChange={handlePaymentChange}
                />
                <div className="payLogoDiv">
                  <img className="payLogos" src={kakaoImg} alt="" />
                </div>
                <p className="payTextColor">카카오 페이</p>
              </div>
            </label>
            <label htmlFor="radios3">
              <div
                className={`text-center paymentBox ${
                  payType === "samsungpay" ? `selected-payment` : ""
                }`}
              >
                <input
                  className="payRadioPayment"
                  type="radio"
                  name="payment"
                  id="radios3"
                  value="samsungpay"
                  onChange={handlePaymentChange}
                />
                <div className="payLogoDiv">
                  <img className="payLogos" src={samsungImg} alt="" />
                </div>
                <p className="payTextColor">삼성페이</p>
              </div>
            </label>
            <label htmlFor="radios4">
              <div
                className={`text-center paymentBox ${
                  payType === "naverpay" ? `selected-payment` : ""
                }`}
              >
                {" "}
                <input
                  className="payRadioPayment"
                  type="radio"
                  name="payment"
                  id="radios4"
                  value="naverpay"
                  onChange={handlePaymentChange}
                />
                <div className="payLogoDiv">
                  <img className="payLogos" src={naverImg} alt="" />
                </div>
                <p className="payTextColor">네이버 페이</p>
              </div>
            </label>
          </div>
          <div
            className="mb-4 d-flex justify-content-conter"
            style={{
              justifyContent: `center`,
            }}
          >
            {/* <label htmlFor="radios5">
                <div
                  className={`text-center paymentBox ${
                    payType === "payfall" ? `selected-payment` : ""
                  }`}
                >
                  <input
                    className="payRadioPayment"
                    type="radio"
                    name="payment"
                    id="radios5"
                    value="payfall"
                    onChange={handlePaymentChange}
                  />
                  <div className="payLogoDiv">
                    <img className="PayPal" src={samsungImg} alt="" />
                  </div>
                  <p className="payTextColor">PayPal</p>
                </div>
              </label> */}
          </div>
          <div className="mb-3">
            <h3>
              <strong>약관 동의</strong>
            </h3>
          </div>
          <div className="mb-3 border-bottom">
            <div className="d=flex justify-content-between position-relative">
              <label htmlFor="allAgree">
                <div
                  className={`d-flex p-2 ${
                    isSafari ? "" : `align-items-center`
                  }`}
                >
                  <input
                    type="checkbox"
                    className={`policy ${
                      isSafari ? "checkbox-margin safari-checkbox" : ""
                    }`}
                    id="allAgree"
                    checked={allAgree}
                    onChange={handleAllAgreeChange}
                  />
                  <h5 className="ms-2">전체동의</h5>
                </div>
              </label>
              <img
                className="position-absolute arrow"
                src="/public/webimg/arrow-down.svg"
                alt=""
              />
            </div>
            <div className="d=flex justify-content-between position-relative">
              <label htmlFor="coffeePolicy">
                <div
                  className={`d-flex p-2 ${
                    isSafari ? "" : `align-items-center`
                  }`}
                >
                  <input
                    type="checkbox"
                    // className="policy"
                    className={`policy ${
                      isSafari ? "checkbox-margin safari-checkbox" : ""
                    }`}
                    id="coffeePolicy"
                    checked={coffeePolicy}
                    onChange={(e) => setCoffeePolicy(e.target.checked)}
                  />
                  <h5 className="ms-2">이용약관</h5>
                </div>
              </label>
              <IoIosArrowDown
                className="position-absolute arrow"
                onClick={handleShowModal}
              />
              {/* {showModal2 && <ModalTwo closeModal2={handleCloseModal2} />} */}
              {/* {showModal && <ModalOne closeModal={handleCloseModal} />} */}

              {/* <img
                className="position-absolute arrow"
                id="coffeePolicyBtn"
                src=
                alt=""
                onClick={handleShowModal}
              /> */}
            </div>
            {/* {showModal && <ModalOne closeModal={handleCloseModal} />} */}
            <div className="d-none" id="coffeePolicyPage">
              이용약관
            </div>
            <div className="d=flex justify-content-between position-relative">
              <label htmlFor="paymentPolicy">
                <div
                  className={`d-flex p-2 ${
                    isSafari ? "" : `align-items-center`
                  }`}
                >
                  {" "}
                  <div>
                    <input
                      type="checkbox"
                      className={`policy ${
                        isSafari ? "checkbox-margin safari-checkbox" : ""
                      }`}
                      id="paymentPolicy"
                      checked={paymentPolicy}
                      onChange={(e) => setPaymentPolicy(e.target.checked)}
                    />
                  </div>
                  <div>
                    <h5 className="ms-2">
                      결제 대행사 결제정보 위탁 동의 약관
                    </h5>
                  </div>
                </div>
              </label>
              <IoIosArrowDown
                className="position-absolute arrow"
                onClick={handleShowModal2}
              />
            </div>

            {/* {showModal2 && <ModalTwo closeModal2={handleCloseModal2} />} */}

            <div className="d-none" id="paymentPolicyPage">
              이용약관
            </div>
          </div>
          <div className="mb-3 p-2 d-flex justify-content-center">
            <button 
              className="btn-pay"
              onClick={()=>handleProductBuy(currentItem,{
                shipping_name : name,
                shipping_phone : phoneNumber,
                shipping_address : fullAddress,
                shipping_memo : info,
                payment_method: payType,
                
              },setIsDisabled)}
              disabled={isDisabled}
            
            >결제하기</button>
          </div>
          {/* {isLoading && <div className="loading-mask">로딩중...</div>} */}
        </div>
        {/* <ModalOne />
          <ModalTwo /> */}
      </div>
    </div>
  );
};

export default PayMentPage;
