import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import MenuCart from "./sub-components/MenuCart";
import { removeCookie } from "../../util/Cookie";
import { resetData } from "../../store/action/ResetAction";
import { useEffect, useState } from "react";
import defaultImg from "../../assets/img/analyze.png";
import icon from "../../assets/img/favicon.png";
import kakaoLogo from "../../assets/img/logo/kakao_login_large_wide.png";

import {
  FlexCenterBox,
 
  ImgProfileBox,
  SizeBoxH15,
  SizeBoxH5,
  SizeBoxW5,
} from "../../assets/style/Common";
import { FaPlusCircle } from "react-icons/fa";
import { RiLoginBoxLine } from "react-icons/ri";

import {

  LoginDropdown,
  LoginDropdown2,
  UserIconDataBoxButtonLeft,
  UserIconDataBoxButtonRight,
} from "../../assets/style/HeaderIcon";
import {

  NoLoginHeaderBox,
  NoLoginHeaderErrorText,
  NoLoginHeaderTextBox,
  NoSizeBox,
  SmallInputBox,
} from "../../assets/style/login-register";
import useLogin from "../../hooks/use-login";
import {
  handleLogin,
  handleLoginEmail,
  handleLoginPW,
} from "../../handler/login/LoginHandler";
import { TokenName } from "../../util/const";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { setTokenAction } from "../../store/action/TokenAction";
import { deleteOrder } from "../../store/slices/order-slice";

const IconGroup = ({ iconWhiteClass, cookieValue }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userData = useSelector((state) => state.userData);
  const countData = useSelector((state) => state.reRandData);
  const count = useSelector((state) => state.reRandData.counter);
  const API_URL = "http://xraidev.store:3003";
  const [isProfile, setProfile] = useState(defaultImg);
  const [isLoading, setLoading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("");

  useEffect(() => {
    // 초기 언어 확인
    const lng = i18next.language || i18next.resolvedLanguage || "en";
    setCurrentLanguage(lng);
    // console.log("변경된언어?", currentLanguage);
    // 언어 변경 이벤트 핸들러
    const handleLanguageChange = (lng) => {
      setCurrentLanguage(lng);
    };
    // i18next 이벤트 등록
    i18next.on("languageChanged", handleLanguageChange);

    // 언마운트 시 이벤트 해제
    return () => {
      i18next.off("languageChanged", handleLanguageChange);
    };
  }, [currentLanguage]);
  useEffect(() => {
    if (userData.profileImage !== null) {
      setProfile(`${API_URL}${userData.profileImage}`);
    }
  }, [userData, countData]);

  const handleLogout = () => {
    removeCookie(TokenName); // 쿠키 삭제
    dispatch(setTokenAction(false));
    dispatch(deleteOrder());
    dispatch(resetData());
    navigate("/");
  };

  const handleRoute = (path, options = {}) => {
    navigate(path, options);
  };

  const handleInfoRoute = (i18) => {
    if (i18 === "en") {
      navigate("/infoEn");
    } else {
      navigate("/infoKo");
    }
  };

  const handleConditionRoute = (i18) => {
    if (i18 === "en") {
      navigate("/ConditionEn");
    } else {
      navigate("/ConditionKo");
    }
  };

  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !isLoading) {
      handleLogin(
        isLoginEmail,
        isLoginPassword,
        navigate,
        setLoginEmailError,
        dispatch
      );
    }
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };
  const { compareItems } = useSelector((state) => state.compare);
  const { wishlistItems } = useSelector((state) => state.wishlist);
  const { cartItems } = useSelector((state) => state.cart);

  const {
    isLoginEmail,
    setLoginEmail,
    isLoginPassword,
    setLoginPassword,
    isLoginEmailError,
    setLoginEmailError,
  } = useLogin();


  const Kakao = window.Kakao;
  const initKakao = () => {
    const jsKey = "e8608dae638b2794cea42ce28156eb2c";
    // const Kakao = window.Kakao;
    if (Kakao && !Kakao.isInitialized()) {
      Kakao.init(jsKey);
      // console.log(Kakao.isInitialized());
    }
  };
  useEffect(() => {
    initKakao();
  }, []);
   const kakaoHandleLogin = async () => {
    console.log("이거 선택된거임?")
  
    Kakao.Auth.authorize({
      // redirectUri: `${API_URL}/kakaoLogin`,
      redirectUri: `http://xraidev.store/kakaoLogin`,
      state: "sendfriend_feed",
      scope: "profile_nickname,account_email,profile_image,phone_number,name",
      nonce: "ceazer",
    });
  };

  return (
    <div className={clsx("header-right-wrap", iconWhiteClass)}>
      {/* <div className="same-style header-search d-none d-lg-block">
        <button className="search-active" onClick={(e) => handleClick(e)}>
          <i className="pe-7s-search" />
        </button>
        <div className="search-content">
          <form action="#">
            <input type="text" placeholder="Search" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
      </div> */}
      <div
        className="same-style account-setting d-none d-lg-block"
        style={{
          marginLeft: `20px`,
        }}
      >
        {cookieValue === true ? (
          <button
            className="account-setting-active"
            onClick={() => handleLogout()}
          >
            {/* <i className="pe-7s-user-female" /> */}
            <span style={{ fontWeight: `500`, fontSize: `14px` }}>
              {t("log_out")}
            </span>
          </button>
        ) : (
          <div></div>
        )}
      </div>
      <div className="same-style account-setting d-none d-lg-block">
        {cookieValue === true ? (
          <button
            className="account-setting-active"
            onClick={(e) => handleClick(e)}
          >
            {/* <i className="pe-7s-user-female" /> */}
            <span style={{ fontWeight: `500`, fontSize: `14px` }}>
              {t("my_moha")}
            </span>
          </button>
        ) : (
          // <div
          //   className="account-setting-active"
          //   onClick={(e) => handleClick(e)}
          // >
          //   <HeaderProfileBox>
          //     <ImgBox2 src={`${isProfile}?t=${count + 1}`} alt="Profile" />
          //   </HeaderProfileBox>
          //   <div>
          //     <i
          //       className="pe-7s-user-female"
          //       style={{
          //         fontSize: `28px`,
          //       }}
          //     />
          //     <div>마이 Moha</div>
          //   </div>
          // </div>
          <button
            className="account-setting-active"
            onClick={(e) => handleClick(e)}
          >
            {/* <i className="pe-7s-user-female" /> */}
            <span style={{ fontWeight: `500`, fontSize: `14px` }}>
              {t("login_sing")}
            </span>
          </button>
        )}

        {/* className="account-dropdown" */}

        {cookieValue !== true && (
          <LoginDropdown>
            <ul>
              <div>
                <FlexCenterBox>
                  <NoLoginHeaderBox>
                    <FlexCenterBox>
                      <div
                        style={{
                          width: `40px`,
                          height: `30px`,
                        }}
                      >
                        <ImgProfileBox src={icon} />
                      </div>
                    </FlexCenterBox>
                    <NoLoginHeaderTextBox>
                      {t("email_address")}
                    </NoLoginHeaderTextBox>
                    <SmallInputBox
                      marginBottom="0px"
                      type="text"
                      placeholder="Username"
                      value={isLoginEmail}
                      onChange={(e) => {
                        handleLoginEmail(e, setLoginEmail);
                      }}
                      onKeyDown={handleKeyDown}
                      hasError={isLoginEmailError}
                    />
                    <NoLoginHeaderTextBox>
                      {t("password_text")}
                    </NoLoginHeaderTextBox>
                    <SmallInputBox
                      marginBottom="0px"
                      type="password"
                      placeholder="Password"
                      value={isLoginPassword}
                      onChange={(e) => handleLoginPW(e, setLoginPassword)}
                      onKeyDown={handleKeyDown}
                      hasError={isLoginEmailError}
                    />
                    {isLoginEmailError === true ? (
                      <NoLoginHeaderErrorText>
                        {t("email_error_msg")}
                      </NoLoginHeaderErrorText>
                    ) : (
                      <NoSizeBox></NoSizeBox>
                    )}
                  </NoLoginHeaderBox>
                </FlexCenterBox>
                <FlexCenterBox>
                  <UserIconDataBoxButtonRight
                    onClick={() =>
                      handleLogin(
                        isLoginEmail,
                        isLoginPassword,
                        navigate,
                        setLoginEmailError,
                        dispatch
                      )
                    }
                  >
                    <RiLoginBoxLine size={13} />
                    <SizeBoxW5 />
                    {/* {t("login_register_login")} */}
                    {t("login_register_login")}
                  </UserIconDataBoxButtonRight>
                  <SizeBoxW5 />
                  <UserIconDataBoxButtonLeft
                    onClick={() =>
                      handleRoute("/login-register", {
                        state: { tab: "register" },
                      })
                    }
                  >
                    <FaPlusCircle size={13} />
                    <SizeBoxW5 />
                    {t("login_register_register")}
                  </UserIconDataBoxButtonLeft>
                </FlexCenterBox>
                <SizeBoxH15 />
                <FlexCenterBox>
                  <div
                    style={{
                      cursor: `pointer`,
                      width: `250px`,
                      height: `40px`
                    }}
                    onClick={() => kakaoHandleLogin()} 
                  >
                    <ImgProfileBox src={kakaoLogo}></ImgProfileBox>
                  </div>
                </FlexCenterBox>

                {/* <li>
                  <Link to={process.env.PUBLIC_URL + "/login-register"}>
                    Login
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/login-register"}>
                    Register
                  </Link>
                </li> */}
              </div>
            </ul>
          </LoginDropdown>
        )}

        {cookieValue === true && (
          <LoginDropdown2>
            <ul
              style={{
                padding: `10px`,
              }}
            >
              {/* <li>
                <Link to={process.env.PUBLIC_URL + "/cart"}>
                  {" "}
                  - {t("cart")}
                </Link>
              </li> */}
              <li>
                <Link to={process.env.PUBLIC_URL + "/order-list"}>
                  {t("order_list")}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/my-account"}>
                  {t("my_account")}
                </Link>
              </li>

              {/* <div>
                <SizeBoxH15 />
                <FlexCenterBox>
                  <HeaderEmailBox>{userData?.email}</HeaderEmailBox>
                </FlexCenterBox>
                <SizeBoxH15 />
                <FlexCenterBox>
                  <HeaderProfileBox width="80px" height="80px">
                    <ImgBox src={`${isProfile}?t=${count + 1}`} alt="Profile" />{" "}
                  </HeaderProfileBox>
                </FlexCenterBox>
                <SizeBoxH15 />
                <FlexCenterBox>
                  <UserIconDataBoxButtonRight
                    onClick={() => handleRoute("/my-account")}
                  >
                    <FaPlusCircle size={13} />
                    <SizeBoxW5 />
                    {t("my_account")}
                  </UserIconDataBoxButtonRight>
                  <SizeBoxW5 />
                  <UserIconDataBoxButtonLeft onClick={(e) => handleLogout(e)}>
                    <RiLogoutBoxLine size={15} />
                    <SizeBoxW5 />
                    {t("log_out")}
                  </UserIconDataBoxButtonLeft>
                </FlexCenterBox>
              </div> */}
              <SizeBoxH5 />
              {/* <FlexCenterBox>
                <HeaderSmallButtonBox
                  onClick={() => handleInfoRoute(currentLanguage)}
                >
                  {t("privacy_policy")}
                </HeaderSmallButtonBox>
                <ZumBox>∙</ZumBox>
                <HeaderSmallButtonBox
                  onClick={() => handleConditionRoute(currentLanguage)}
                >
                  {t("terms_service")}
                </HeaderSmallButtonBox>
              </FlexCenterBox> */}
              {/* <li>
                  <Link to={process.env.PUBLIC_URL + "/my-account"}>
                    my account
                  </Link>
                </li>
                <li>
                  <Link
                    to={process.env.PUBLIC_URL + "/login-register"}
                    onClick={handleLogout}
                  >
                    LogOut
                  </Link>
                </li> */}
            </ul>
          </LoginDropdown2>
        )}
      </div>

      {/* <div className="same-style header-compare">
        <Link to={process.env.PUBLIC_URL + "/compare"}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">
            {compareItems && compareItems.length ? compareItems.length : 0}
          </span>
        </Link>
      </div> */}
      {cookieValue === true && (
        <>
          {/* <div className="same-style header-wishlist">
            <Link to={process.env.PUBLIC_URL + "/wishlist"}>
              <i className="pe-7s-like" />
              <span className="count-style">
                {wishlistItems && wishlistItems.length
                  ? wishlistItems.length
                  : 0}
              </span>
            </Link>
          </div> */}
          <div className="same-style cart-wrap d-none d-lg-block">
            <button className="icon-cart" onClick={(e) => handleClick(e)}>
              <i
                className="pe-7s-shopbag"
                style={{
                  fontSize: `28px`,
                }}
              />
              <span className="count-style">
                {cartItems && cartItems.length ? cartItems.length : 0}
              </span>
            </button>
            {/* menu cart */}
            <MenuCart />
          </div>
        </>
      )}

      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartItems && cartItems.length ? cartItems.length : 0}
          </span>
        </Link>
      </div>
      {/* <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div> */}
    </div>
  );
};

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
};

export default IconGroup;
