import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { Table, Td, Th } from "../../assets/style/info";

const InfoEnPage = () => {
  let { pathname } = useLocation();

  return (
    <Fragment>
      <SEO
        titleTemplate="Not Found"
        description="404 of flone react minimalist eCommerce template."
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        {/* <Breadcrumb 
          pages={[
            {label: "Home", path: process.env.PUBLIC_URL + "/" },
            {label: "error page", path: process.env.PUBLIC_URL + pathname }
          ]} 
        /> */}
        <div className="error-area pt-40 pb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 ">
                <div>
                  <div className="">
                    <h1>MOHA Privacy Notice</h1>
                  </div>
                  <div className="">
                    <p>
                      XRAI Co., Ltd. (hereinafter referred to as the “Company”),
                      which operates MOHA, takes great care in handling our
                      members’ personal information.
                    </p>
                    <p>
                      The Company complies with personal information protection
                      regulations under relevant laws, including the Act on
                      Promotion of Information and Communications Network
                      Utilization and Information Protection, and strives to
                      protect our members' personal information through privacy
                      policies in accordance with relevant laws. Through our
                      privacy policy and personal information protection policy,
                      we inform you about how the personal information provided
                      by users is used, and how it is managed for personal
                      information protection.
                    </p>
                    <p>
                      This Privacy Policy is effective as of December 02, 2024.
                    </p>

                    <h2>1. Collection of Personal Information</h2>
                    <p>
                      The Company collects minimum personal information through
                      its website as follows during the membership registration
                      or service use process:
                    </p>
                    <ul>
                      <li>
                        <strong>Required Information:</strong> Name, date of
                        birth, gender, login ID, password, address, mobile phone
                        number, email, cookies, payment records
                      </li>
                      <li>
                        <strong>Collection Method:</strong> Collection through
                        website membership registration
                      </li>
                    </ul>

                    <h2>2. Purpose of Personal Information Use</h2>
                    <p>
                      The Company uses collected personal information for the
                      following purposes:
                    </p>
                    <ul>
                      <li>
                        <strong>Member Registration and Management:</strong>{" "}
                        Verification of membership registration intent,
                        identification and authentication for membership
                        services, maintenance and management of membership
                        status, prevention of service misuse, sanctions against
                        malicious users, verification of legal guardian consent,
                        etc.
                      </li>
                      <li>
                        <strong>Provision of Goods or Services:</strong>{" "}
                        Fulfillment of service provision contracts, fee
                        calculation for service provision, content provision,
                        purchase and payment processing, shipping of goods or
                        sending of invoices, identity verification, purchase,
                        payment processing, fee collection, income tax filing or
                        tax invoice issuance, verification of legal guardian
                        consent, etc.
                      </li>
                      <li>
                        <strong>Handling Complaints:</strong> Verification of
                        complainant identity, confirmation of complaints,
                        contact for investigation and notification of processing
                        results, preservation of records for dispute mediation,
                        delivery of notices, confirmation of intentions,
                        handling complaints, and securing smooth communication
                        channels
                      </li>
                    </ul>

                    <h2>
                      3. Processing and Retention Period of Personal Information
                    </h2>
                    <p>
                      The Company processes and retains personal information
                      collected from members during the period of membership,
                      and promptly destroys it when the purpose of processing
                      personal information is achieved. However, information may
                      be retained for a certain period if prior consent has been
                      obtained from members or if preservation is necessary
                      according to relevant laws. Current laws related to this
                      include:
                    </p>
                    <ul>
                      <li>
                        <strong>
                          Act on Consumer Protection in Electronic Commerce,
                          etc.:
                        </strong>
                        <ul>
                          <li>
                            Records on contracts or withdrawal of subscription,
                            etc. (5 years)
                          </li>
                          <li>
                            Records on payment and supply of goods, etc. (5
                            years)
                          </li>
                          <li>
                            Records on consumer complaints or dispute handling
                            (3 years)
                          </li>
                          <li>Records on display/advertising (6 months)</li>
                        </ul>
                      </li>
                      <li>
                        <strong>Electronic Financial Transactions Act:</strong>{" "}
                        Records on electronic financial transactions (5 years)
                      </li>
                      <li>
                        <strong>
                          Protection of Communications Secrets Act:
                        </strong>{" "}
                        Login records (3 months)
                      </li>
                      <li>
                        When consent is obtained from members: Until the period
                        agreed upon by members
                      </li>
                      <li>
                        Regardless of member consent and related laws, personal
                        information may be retained until the end of the
                        relevant cause in the following cases:
                        <ul>
                          <li>
                            Until the completion of investigation or examination
                            in case of ongoing investigation due to violation of
                            relevant laws
                          </li>
                          <li>
                            Until the settlement of credits and debts arising
                            from the use of Company services
                          </li>
                        </ul>
                      </li>
                    </ul>

                    <h2>
                      4. Provision of Personal Information to Third Parties
                    </h2>
                    <p>
                      The Company processes personal information only within the
                      scope specified in the processing purpose. However,
                      personal information may be provided to third parties in
                      the following cases:
                    </p>
                    <ul>
                      <li>When prior consent is obtained from members</li>
                      <li>
                        When required by law or requested by investigative
                        agencies for investigation purposes following procedures
                        and methods prescribed by law
                      </li>
                      <li>When there are special provisions in laws</li>
                      <li>
                        When settlement is required for member's service use
                      </li>
                      <li>
                        When there are legitimate reasons preventing obtaining
                        consent from the information subject in cases of urgent
                        risk to life and body
                      </li>
                      <li>
                        When providing personal information in a form that
                        cannot identify specific individuals for statistical
                        compilation and academic research purposes
                      </li>
                    </ul>

                    <h2>5. Outsourcing of Personal Information Processing</h2>
                    <p>
                      The Company outsources personal information processing to
                      professional companies as follows for smooth business
                      operations, with the consent of members or for purposes of
                      contract conclusion, maintenance, understanding, and
                      management:
                    </p>
                    <table border="1" cellpadding="5">
                      <thead>
                        <tr>
                          <th>Contractor</th>
                          <th>Tasks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Payletter Co., Ltd.</td>
                          <td>
                            Credit card payment approval and purchase agency,
                            account transfer, simple payment (Naver Pay/Kakao
                            Pay/Toss Pay), identity verification
                          </td>
                        </tr>
                        <tr>
                          <td>KakaoPay Co., Ltd.</td>
                          <td>
                            Kakao Pay payment agency and cash receipt
                            application entrustment, identity verification
                          </td>
                        </tr>
                        <tr>
                          <td>Viva Republica Co., Ltd.</td>
                          <td>
                            Toss payment agency and cash receipt application
                            entrustment, identity verification
                          </td>
                        </tr>
                        <tr>
                          <td>PayPal</td>
                          <td>
                            PayPal payment agency and identity verification
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <h2>
                      6. Procedures and Methods for Destroying Personal
                      Information
                    </h2>
                    <p>
                      The Company destroys personal information without delay
                      when it is no longer necessary due to the expiration of
                      the retention period or achievement of the processing
                      purpose. However, if personal information must continue to
                      be preserved for other reasons or laws despite the
                      expiration of the retention period or achievement of the
                      processing purpose, the Company moves such information to
                      a separate database or stores it in a different physical
                      location in the following cases:
                    </p>
                    <ul>
                      <li>
                        When the Company retains personal information as
                        evidence for civil/criminal liability or ongoing statute
                        of limitations or disputes
                      </li>
                      <li>
                        When preservation is required by laws such as Article 33
                        of the Commercial Act
                      </li>
                      <li>Other similar legitimate reasons</li>
                    </ul>
                    <p>
                      <strong>Destruction Method:</strong> Personal information
                      recorded and stored in electronic file format is destroyed
                      in a manner that prevents reproduction of records, and
                      personal information recorded and stored in paper
                      documents is destroyed by shredding or incineration.
                    </p>

                    <h2>
                      7. Rights of Members and Legal Representatives and Their
                      Exercise
                    </h2>
                    <p>
                      Members and legal representatives (in case of members
                      under 14) can exercise the following personal information
                      protection rights at any time:
                    </p>
                    <ul>
                      <li>Request to view personal information</li>
                      <li>Request for correction in case of errors</li>
                      <li>
                        Request for deletion (however, such requests may not be
                        accepted if the personal information is specified as a
                        collection target in other laws)
                      </li>
                      <li>
                        Request for withdrawal or suspension of personal
                        information processing (some or all services may be
                        difficult to use)
                      </li>
                    </ul>
                    <p>
                      Viewing registered personal information is possible after
                      completing identity verification procedures (or legal
                      representative verification) as determined by the Company.
                      Withdrawal is also possible after completing identity
                      verification through the Company's prescribed procedures.
                    </p>

                    <h2>8. Protection of Children’s Personal Information</h2>
                    <p>
                      According to Article 31, Paragraph 1 of the Act on
                      Promotion of Information and Communications Network
                      Utilization and Information Protection, the Company must
                      obtain consent from legal representatives when collecting
                      personal information of children under 14 years of age.
                      Therefore, the Company does not collect personal
                      information from children under 14 years of age, nor do we
                      allow them to become members. However, children under 14
                      may become members after obtaining consent and written
                      certification from their legal representatives.
                      Additionally, legal representatives of children under 14
                      can request to view, correct, or withdraw consent for the
                      child's personal information, and the Company will take
                      necessary measures without delay when such requests are
                      made.
                    </p>

                    <h2>
                      9. Installation, Operation, and Rejection of Automatic
                      Personal Information Collection Devices
                    </h2>
                    <p>
                      The Company installs and operates devices or technologies
                      that automatically collect personal information, such as
                      cookies, which continuously store and retrieve member
                      information. Cookies are small text files sent by servers
                      operating the Company's website to members' web browsers
                      and stored on members' computers.
                    </p>
                    <p>The Company uses cookies for the following purposes:</p>
                    <ul>
                      <li>
                        Authentication for goods, content, and payment provided
                        by the Company
                      </li>
                      <li>Notification of usage period for paid services</li>
                      <li>
                        Verification of participation history in events and
                        surveys
                      </li>
                      <li>
                        Provision of differentiated information according to
                        user interests
                      </li>
                      <li>
                        Use as a metric for service improvement through analysis
                        of user patterns
                      </li>
                    </ul>
                    <p>
                      However, members have the choice regarding cookie
                      installation and usage rights, and can allow all cookies,
                      verify each time cookies are stored, or reject all cookie
                      storage through browser options settings according to web
                      browser support.
                    </p>
                    <p>
                      Please note that if you reject cookie storage, services
                      requiring login may be restricted.
                    </p>

                    <h2>10. Technical / Administrative Protection Measures</h2>
                    <p>
                      The Company implements the following technical and
                      administrative protection measures to prevent loss, theft,
                      leakage, alteration, or damage of members' personal
                      information:
                    </p>
                    <ul>
                      <li>
                        <strong>Access Control and Authority Rights:</strong>
                        The Company limits access rights to personal information
                        to the minimum necessary scope for performing duties.
                        Different authorities are granted to each task manager
                        through individual accounts, and access rights to
                        personal information are changed or revoked when
                        personal information handlers and assigned tasks change.
                        Additionally, when personal information managers and
                        handlers access personal information from outside,
                        secure connection means such as VPN and secure
                        connections are used, and measures are taken on systems
                        and personal PCs for personal information inquiry and
                        processing to prevent unauthorized persons from viewing
                        personal information.
                      </li>
                      <li>
                        <strong>Personal Information Encryption:</strong>
                        The Company encrypts all personal information
                        transmitted/received through information and
                        communication networks or auxiliary storage media, and
                        generally does not store personal information on work
                        PCs. If storage is unavoidable, the data is encrypted
                        for safe storage/processing. Also, in accordance with
                        the Personal Information Protection Act, unique
                        identification numbers (resident registration numbers,
                        foreign registration numbers, etc.), passwords, and
                        sensitive personal information are stored and managed
                        using secure encryption algorithms.
                      </li>
                      <li>
                        <strong>
                          Storage and Prevention of Access Record
                          Forgery/Alteration and Physical Measures:
                        </strong>
                        The Company maintains records of personal information
                        handlers' system processing of personal information for
                        at least 3 months and safely stores these records to
                        prevent forgery, alteration, theft, loss, or deletion.
                        Additionally, physical storage locations containing
                        personal information, such as servers, are designated as
                        places that cannot be arbitrarily accessed, and
                        documents, electronic documents, and auxiliary storage
                        media containing personal information are stored in
                        secure locations protected by locking devices.
                      </li>
                    </ul>

                    <h2>11. Personal Information Complaint Services</h2>
                    <p>
                      The Company has designated a personal information
                      protection officer as follows to protect members' personal
                      information and handle related complaints. You can contact
                      us through the following contact information for matters
                      related to personal information, such as viewing and
                      correction requests:
                    </p>
                    <ul>
                      <li>
                        <strong>
                          Personal Information Protection Officer:
                        </strong>{" "}
                        Director Lee Seung-seok
                      </li>
                      <li>
                        <strong>Email:</strong> dltmdtjr9683@xrai-api.com
                      </li>
                    </ul>
                    <p>
                      Members can report all personal information
                      protection-related complaints that occur while using the
                      Company's services through the personal information
                      management officer.
                    </p>

                    <h2>12. Scope of Application of This Privacy Policy</h2>
                    <p>
                      This privacy policy applies to MOHA and related services,
                      which are services of the Company, and different privacy
                      policies may apply to services provided under other
                      brands. Additionally, when personal information is
                      collected on other companies' web/apps linked to MOHA
                      services, the privacy policy of that app/web applies.
                    </p>

                    <h2>13. Changes to Privacy Policy</h2>
                    <p>
                      In case of additions, deletions, or changes to the privacy
                      policy, we will provide advance notice at least 7 days
                      prior when possible, and in case of significant changes to
                      member rights such as changes to collected personal
                      information items or usage purposes, we will provide
                      notice at least 30 days in advance.
                    </p>

                    <h2>15. Provisions by Country</h2>
                    <p>
                      These privacy notices may be translated into one or more
                      other languages. This Privacy Notice will take precedence
                      over the English version.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default InfoEnPage;
