import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import {
  FlexCenterBox,
  SizeBoxW5,
  SpanTextModalBoldOne,
  SpanTextModalBoldTwo,
} from "../../assets/style/Common";
import { ModalTextSpan } from "../../assets/style/Modal";

import logo from "../../assets/img/logo/logo-1.png";
import { useTranslation } from "react-i18next";

const PaymentCompleted = () => {
  const dispatch = useDispatch();
  let { pathname } = useLocation();
  const { t } = useTranslation();

  const currency = useSelector((state) => state.currency);
  const { compareItems } = useSelector((state) => state.compare);
  const { cartItems } = useSelector((state) => state.cart);

  return (
    <Fragment>
      <SEO
        titleTemplate="PaymentCompleted"
        description="PaymentCompleted page of flone react minimalist eCommerce template."
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
        // pages={[
        //   { label: "Home", path: process.env.PUBLIC_URL + "/" },
        //   {
        //     label: "PaymentCompleted",
        //     path: process.env.PUBLIC_URL + pathname,
        //   },
        // ]}
        />
        <div className="compare-main-area pt-90 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="item-empty-area text-center">
                  {/* <div className="item-empty-area__icon mb-30">
                    <img
                      src={logo}
                      alt="로고"
                      style={{
                        width: `400px`,
                        marginBottom: `20px`,
                      }}
                    />
                  </div> */}
                  <div
                    className="item-empty-area__text"
                    style={{
                      marginTop: `10vh`,
                      marginBottom: `10vh`,
                    }}
                  >
                    <FlexCenterBox marginTop="0px">
                      <SpanTextModalBoldOne fontSize="20px">
                        M
                      </SpanTextModalBoldOne>
                      <SpanTextModalBoldTwo fontSize="20px">
                        O
                      </SpanTextModalBoldTwo>
                      <SpanTextModalBoldOne fontSize="20px">
                        H
                      </SpanTextModalBoldOne>
                      <SpanTextModalBoldTwo fontSize="20px">
                        A
                      </SpanTextModalBoldTwo>
                      <SizeBoxW5></SizeBoxW5>
                      <ModalTextSpan>{t("moha_using")}</ModalTextSpan>
                    </FlexCenterBox>
                    {t("order_success")} <br />{" "}
                    <Link to={process.env.PUBLIC_URL + "/order-list"}>
                      {t("order_detail")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default PaymentCompleted;
