import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const LanguageCurrencyChanger = () => {
  const { i18n } = useTranslation();
  
  const changeLanguageTrigger = e => {
    const languageCode = e.target.value;
    i18n.changeLanguage(languageCode);
  };

  const getLanguageName = (code) => {
    switch(code) {
      case "ko":
        return "한국어";
      case "en":
        return "English";
      default:
        return "한국어";
    }
  };

  return (
    <div className="language-currency-wrap">
      <div className="same-language-currency language-style">
        <span>
          {getLanguageName(i18n.resolvedLanguage)}{" "}
          <i className="fa fa-angle-down" />
        </span>
        <div className="lang-car-dropdown">
          <ul>
            <li>
              <button value="ko" onClick={e => changeLanguageTrigger(e)}>
                한국어
              </button>
            </li>
            <li>
              <button value="en" onClick={e => changeLanguageTrigger(e)}>
                English
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="same-language-currency">
        {/* <p>고객센터 3965410</p> */}
      </div>
    </div>
  );
};

LanguageCurrencyChanger.propTypes = {};

export default LanguageCurrencyChanger;