export const SET_TOKEN = "SET_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";

export const setTokenAction = (isTokenSet) => ({
  type: SET_TOKEN,
  payload: isTokenSet,
});

export const removeTokenAction = () => ({
  type: REMOVE_TOKEN,
});
