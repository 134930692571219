import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import { setProducts, fetchProducts } from "./store/slices/product-slice";
// import products from "./data/products.json";
import "animate.css";
import "swiper/swiper-bundle.min.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import "./i18n";
import { GlobalStyle } from "./assets/style/GlobalStyle";
// 경고를 없애는 코드
// const noWarn = () => {};
// console.warn = noWarn; // 모든 경고를 제거

// store.dispatch(setProducts(products));
// store.dispatch(fetchProducts({ limit: 100 })); // 초기에 충분한 데이터를 로드

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistProvider>
      <GlobalStyle />
      <App />
    </PersistProvider>
  </Provider>
);
