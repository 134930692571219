import api from "../axiosConfig";

export const AuthResetPassword = async (token, pw) => {
  try {
    const data = {
      token: token,
      new_password: pw,
    };

    const resp = await api.post("/api/v1/auth/reset-password", data);

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("비밀번호 변경 실패", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
