import axios from "axios";
import { useEffect , useState} from "react";
import { useNavigate } from "react-router";
import { API_URL } from "../../api/axiosConfig";
import { setCookie } from "../../util/Cookie";
import { TokenName, convertToDomesticNumber } from "../../util/const";
import { setTokenAction } from "../../store/action/TokenAction";
import { useDispatch } from "react-redux";
import { AuthMe } from "../../api/Authentication/AuthLogin";
import { setUserData } from "../../store/action/UserAction";


 export const KakaoReduredctURL = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
      const fetchAuthData = async () => {
         setLoading(true);

         try {
            const code = new URL(window.location.href).searchParams.get("code");
            const state = new URL(window.location.href).searchParams.get("state");

            console.log("이게뭐지?", code);
            const resp = await axios.post(
               `${API_URL}/api/v1/auth/kakao/login`,
               null,
               {
                  params: {
                     code: code
                  }
               }
            );

            // const data = resp.data;
            
            console.log("여기는찍히냐?22", resp)

         
            if (resp.status == 200) {

               console.log("여기는찍히냐?", resp)
               setCookie(TokenName, resp.data.access_token, {
                  path: "/",
                  maxAge: 3600,
               });
               dispatch(setTokenAction(true));

               console.log("이거 아닌가?", resp.data.access_token)
               const userResp = await AuthMe(resp.data.access_token);

               if (userResp.msg === "Success") {

                  console.log("이거 정보좀 보자", userResp.data.data)

                  const updatedUserData = {
                     ...userResp.data.data,        // 기존의 userResp 데이터
                     phone: convertToDomesticNumber(userResp.data.data.phone),  // 전화번호만 변환
                   };


                   console.log("잘 변했니?", updatedUserData);
                                     dispatch(setUserData(updatedUserData));
                                                       navigate("/");
               }
               


               if (userResp.msg === "Fail") {
                  console.log("Fail", userResp);
                  navigate("/login-register")
               }
            }

         } catch(error) {

         }

      }
      fetchAuthData();
    }, []);

    return <div></div>;

 }