import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { resetAuthError } from "../store/action/AuthGuardAction";
import { resetData } from "../store/action/ResetAction";
import { getCookie, removeCookie } from "./Cookie";
import { TokenName } from "./const";
import { setTokenAction } from "../store/action/TokenAction";
import { deleteOrder } from "../store/slices/order-slice";

export const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authError = useSelector((state) => state.authState.error);
  const isTokenSet = useSelector((state) => state.tokenState);
  const location = useLocation(); // 현재 페이지 경로 가져오기
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  // 1. 토큰 유무와 상관없이 접속이 가능한 페이지
  const alwaysPages = ["/"];

  // 2. 토큰이 있을경우 접속이 불가능한 페이지
  const yesTokenPage = ["/forgotPw", "/reset-password", "/login-register"];

  // 3. 토큰이 없는경우 접속이 불가능한 페이지
  const noTokenPage = ["/my-account", "/order-list", "/OrderDetailPage", "/checkout"];

  useEffect(() => {
    if (authError === 401 || authError === 403) {
      removeCookie(TokenName);
      dispatch(resetAuthError());
      dispatch(resetData());  
      dispatch(setTokenAction(false))    
      dispatch(deleteOrder())
      navigate("/login-register");
    }
  }, [authError, dispatch, navigate]);

  useEffect(() => {
    // if (isTokenSet.isTokenSet === false) {
      // removeCookie(TokenName);
    // }
    // setTimeout(() => {
    //   const token = getCookie(TokenName);
    //   console.log("토큰 삭제 후 확인:", token); // 여기서 삭제된 상태를 확인
    // }, 500);
  }, [dispatch, navigate]);

  useEffect(() => {
    /// 토큰이 있을경우 접속이 불가능
    if (isTokenSet.isTokenSet && yesTokenPage.includes(location.pathname)) {
      navigate("/login-register");
    }

    /// 로그인 상태에서만 접속할 수 있는 페이지
    if (
      isTokenSet.isTokenSet === false &&
      noTokenPage.includes(location.pathname)
    ) {
      navigate("/login-register");
    }

    setLoading(false);
  }, [isTokenSet, location.pathname, dispatch, navigate]);

  if (loading) {
    return null; // 로딩 중일 때는 아무것도 렌더링하지 않음
  }

  return <>{children}</>;
};
