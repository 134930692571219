import { IoIosClose } from "react-icons/io";
import logo from "../assets/img/logo/logo-1.png";
import {
  AddressCloseButton,
  AddressImgBox,
  AddressLogoImg,
  AddressModalBackdrop,
  AddressModalContainer,
  AddressTopDiv,
} from "../assets/style/AddressModal";
import {
  FlexCenterBox,
  SizeBoxH30,
  SizeBoxH5,
  SizeBoxW5,
  SpanTextModalBoldOne,
  SpanTextModalBoldTwo,
} from "../assets/style/Common";
import { ModalTextSpan } from "../assets/style/Modal";
import Success from "../assets/img/analyze_complate.png";
import { EmailCheckButton } from "../assets/style/login-register";
import { useTranslation } from "react-i18next";
const PwChangeModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <AddressModalBackdrop onClick={handleBackdropClick}>
      <AddressModalContainer>
        <AddressTopDiv>
          <AddressImgBox>
            <AddressLogoImg src={logo} alt="로고" />
          </AddressImgBox>
          <AddressCloseButton onClick={onClose}>
            <IoIosClose size={35} />
          </AddressCloseButton>
        </AddressTopDiv>
        <SizeBoxH5 />
        <FlexCenterBox>
          <img src={Success} alt="" />
        </FlexCenterBox>
        <SizeBoxH30 />
        <FlexCenterBox marginTop="0px">
          <SpanTextModalBoldOne fontSize="20px">M</SpanTextModalBoldOne>
          <SpanTextModalBoldTwo fontSize="20px">O</SpanTextModalBoldTwo>
          <SpanTextModalBoldOne fontSize="20px">H</SpanTextModalBoldOne>
          <SpanTextModalBoldTwo fontSize="20px">A</SpanTextModalBoldTwo>
          <SizeBoxW5></SizeBoxW5>
          <ModalTextSpan>{t("success_change-pw")}</ModalTextSpan>
        </FlexCenterBox>
        <SizeBoxH30 />
        <FlexCenterBox className="button-box-custom">
          <EmailCheckButton onClick={handleBackdropClick}>
            {t("ok_text")}
          </EmailCheckButton>{" "}
        </FlexCenterBox>
      </AddressModalContainer>
    </AddressModalBackdrop>
  );
};

export default PwChangeModal;
